import request from "@/utils/request";

// 删除人才信息
export function deleteTalentData(data) {
  return request({
    url: "/system/person/deletePerson",
    method: "post",
    data
  });
}

// 人才信息单个浏览
export function getTalent(data) {
  return request({
    url: "/system/person/personByPersonCode?personCode=" + data,
    method: "get",
  });
}

// 人才信息全部浏览
export function getTalents(data) {
  return request({
    url: "/system/person/getPersonList",
    method: "post",
    data
  });
}

// 添加人才
export function addTalents(data) {
  return request({
    url: "/system/person/addPerson",
    method: "post",
    data
  });
}

// 修改人才信息
export function updateTalents(data) {
  return request({
    url: "/system/person/updatePerson",
    method: "post",
    data
  });
}

// 查询人才联系方式
export function getContact(data) {
  return request({
    url: "/system/personContact/PCList?personCode=" + data,
    method: "get",
  });
}

// 新增编辑人才联系方式
export function addContact(data) {
  return request({
    url: "/system/personContact/addPC",
    method: "post",
    data
  });
}

//查询人才工作经历
export function getExperience(data) {
  return request({
    url: "/system/personExperience/PEList",
    method: "post",
    data
  });
}

// 新增人才工作经历
export function addExperience(data) {
  return request({
    url: "/system/personExperience/addPE",
    method: "post",
    data
  });
}

// 删除人才工作经历
export function deleteExperience(data) {
  return request({
    url: "/system/personExperience/deletePEByCompanyName?id=" + data.id + "&personCode=" + data.personnelCode + "&personType=" + data.personType,
    method: "get",
  });
}

// 修改人才工作经历
export function editExperience(data) {
  return request({
    url: "/system/personExperience/updatePersonExperience",
    method: "post",
    data
  });
}

// 查询项目经历
export function getProject(data) {
  return request({
    url: "/system/projectExperience/PrEList",
    method: "post",
    data
  });
}

//新增项目经历
export function addProject(data) {
  return request({
    url: "/system/projectExperience/addPrE",
    method: "post",
    data
  });
}

// 修改项目经历
export function editProject(data) {
  return request({
    url: "/system/projectExperience/updateProjectExperience",
    method: "post",
    data
  });
}

// 删除项目经历
export function deleteProject(data) {
  return request({
    url: "/system/projectExperience/deletePrEByCompanyName?id=" + data.id + "&personCode=" + data.personCode + "&personType=" + data.personType,
    method: "get",
  });
}

// 查询教育经历
export function getEducation(data) {
  return request({
    url: "/system/personEducational/PeEList",
    method: "post",
    data
  });
}

// 新增教育经历
export function addEducation(data) {
  return request({
    url: "/system/personEducational/addPeE",
    method: "post",
    data
  });
}

// 编辑教育经历
export function editEducation(data) {
  return request({
    url: "/system/personEducational/updatePeE",
    method: "post",
    data
  });
}

// 删除教育经历
export function deleteEducation(data) {
  return request({
    url: "/system/personEducational/deletePeE?id=" + data.id + "&personCode=" + data.personCode + "&personType=" + data.personType,
    method: "get",
  });
}

// 查询语言能力
export function getLanguage(data) {
  return request({
    url: "/system/language/LanguageList",
    method: "post",
    data
  });
}

// 添加语言能力
export function addLanguage(data) {
  return request({
    url: "/system/language/addLanguage",
    method: "post",
    data
  });
}

// 修改语言能力
export function editLanguage(data) {
  return request({
    url: "/system/language/updateLanguage",
    method: "post",
    data
  });
}

// 删除语言能力
export function deleteLanguage(data) {
  return request({
    url: "/system/language/deleteLanguage?id=" + data.id + "&personCode=" + data.personCode + "&personType=" + data.personType,
    method: "get",
  });
}

// 查询附加信息
export function getAdditionInfo(data) {
  return request({
    url: "/system/additionalInformation/AdditionalInformationList?personnelCode=" + data,
    method: "get",
  });
}


// 修改附加信息
export function editAdditionInfo(data) {
  return request({
    url: "/system/additionalInformation/addAdditionalInformation",
    method: "post",
    data
  });
}

// 查询专利
export function getPatent(data) {
  return request({
    url: "/system/patent/PatentList",
    method: "post",
    data
  });
}

// 添加专利
export function addPatent(data) {
  return request({
    url: "/system/patent/addPatent ",
    method: "post",
    data
  });
}

// 编辑专利
export function editPatent(data) {
  return request({
    url: "/system/patent/updatePatent",
    method: "post",
    data
  });
}

// 删除专利
export function deletePatent(data) {
  return request({
    url: "/system/patent/deletePatent?id=" + data.id + "&personCode=" + data.personCode + "&personType=" + data.personType,
    method: "get",
  });
}

// 查询论文
export function getPaper(data) {
  return request({
    url: "/system/thesis/ThesisList ",
    method: "post",
    data
  });
}

// 新增论文
export function addPaper(data) {
  return request({
    url: "/system/thesis/addThesis",
    method: "post",
    data
  });
}

// 编辑论文
export function editPaper(data) {
  return request({
    url: "/system/thesis/updateThesis",
    method: "post",
    data
  });
}

// 删除论文
export function deletePaper(data) {
  return request({
    url: "/system/thesis/deleteThesis?id=" + data.id + "&personType=" + data.personType + "&personCode=" + data.personCode,
    method: "get",
  });
}

// 查询科研项目
export function getRearchProject(data) {
  return request({
    url: "/system/project/ProjectList",
    method: "post",
    data
  });
}

// 添加科研项目
export function addRearchProject(data) {
  return request({
    url: "/system/project/addProject",
    method: "post",
    data
  });
}

// 修改科研项目
export function editRearchProject(data) {
  return request({
    url: "/system/project/updateProject",
    method: "post",
    data
  });
}

// 删除科研项目
export function deleteRearchProject(data) {
  return request({
    url: "/system/project/deleteProject?id=" + data.id + "&personType=" + data.personType + "&personCode=" + data.personCode,
    method: "get",
  });
}

// 上传附件
export function uploadFiles(data) {
  return request({
    url: "/system/public/uploadAdmin",
    method: "post",
    data
  });
}

// 添加附件
export function addFile(data) {
  return request({
    url: "/system/file/addFile",
    method: "post",
    data
  });
}

// 查看附件
export function getFile(data) {
  return request({
    url: "/system/file/FileList",
    method: "post",
    data
  });
}

export function deleteFile(data) {
  return request({
    url: "/system/file/deleteFile?id=" + data.id + "&personType=" + data.personType + "&personCode=" + data.personCode,
    method: "get",
  });
}

export function getFileOne(data) {
  return request({
    url: "/system/file/getFileOne?id=" + data,
    method: "get",
  });
}

// 智能查询
export function samrtFind(data) {
  return request({
    url: "/system/person/byCompanyName?companyName=" + data,
    method: "get",
  });
}

// 人才数据导入
export function uploadImportTalent(data) {
  return request({
    url: "/system/person/importPersons",
    method: "post",
    data
  })
}

// 头像导入导入
export function uploadImportTalentPro(data) {
  return request({
    url: "/system/person/updateHeadPortrait",
    method: "post",
    data
  })
}

// ===============================================

// 人才标签-新增
export function insertPersonLabel(data) {
  return request({
    url: "/system/personLabel/insertPersonLabel",
    method: "post",
    data
  })
}

// 编辑人才标签
export function updatePersonLabel(data) {
  return request({
    url: "/system/personLabel/updatePersonLabel",
    method: "post",
    data
  })
}

// 人才标签信息 （personId）
export function selectPersonLabelDetail(query) {
  return request({
    url: "/system/personLabel/selectPersonLabelDetail",
    method: "get",
    params: query
  });
}

// 手动打标签新增
export function insertPersonLabelManual(data) {
  return request({
    url: "/system/personLabel/insertPersonLabelManual",
    method: "post",
    data
  })
}

// 手动打标签编辑更新
export function updatePersonLabelManual(data) {
  return request({
    url: "/system/personLabel/updatePersonLabelManual",
    method: "post",
    data
  })
}

// 查询当前产品所有的标签（personId）
export function getById(query) {
  return request({
    url: "/system/personLabel/getById",
    method: "get",
    params: query
  });
}

// 半自动标签新增编辑 (id)
export function insertAndUpdatePersonMatchedLabel(data) {
  return request({
    url: "/system/label/insertAndUpdatePersonMatchedLabel",
    method: "post",
    data
  })
}

// 通过ID查询历史匹配标签--人才（personId）
export function findHistroyMatchedLabelByPerson(query) {
  return request({
    url: "/system/label/findHistroyMatchedLabelByPerson",
    method: "get",
    params: query
  });
}

// 查询所属企业
export function getCompanyName(query) {
  return request({
    url: "/system/person/getCompanyName",
    method: "get",
    params: query
  });
}

// 其他能力查询
export function getPersonOther(query) {
  return request({
    url: "/system/personOther/getPersonOther",
    method: "get",
    params: query
  });
}

// 其他能力编辑
export function updateOtherPerson(data) {
  return request({
    url: "/system/personOther/updateOtherPerson",
    method: "post",
    data
  })
}
