<template>
  <div>
    <div class="contact">
      <el-form :model="ruleForm" ref="topRuleForm" label-width="100px">
        <el-form-item label="联系电话:">
          <el-input v-model="ruleForm.phone"></el-input>
        </el-form-item>
        <el-form-item label="微信:">
          <el-input v-model="ruleForm.wechat"></el-input>
        </el-form-item>
        <el-form-item label="传真:">
          <el-input v-model="ruleForm.fax"></el-input>
        </el-form-item>
        <el-form-item label="地址:">
          <el-input v-model="ruleForm.address"></el-input>
        </el-form-item>
        <el-form-item label="电子邮箱:">
          <el-input v-model="ruleForm.email"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <!-- <div class="bottom" v-if="personType">
      <el-button @click="submitForm()" type="primary">确定</el-button>
      <el-button type="info" @click="cancelItem">取消</el-button>
    </div> -->
  </div>
</template>

<script>
import { addContact, getContact } from "@/api/talent.js";
export default {
  props: {
    personData: {
      type: Object,
    },
    personType: {
      type: Number,
    },
    saveId: {
      type: String,
    },
  },
  data() {
    return {
      ruleForm: {
        phone: "",
        wechat: "",
        fax: "",
        address: "",
        email: "",
      },
      activeName: null,
    };
  },
  computed: {
    Authorization() {
      return sessionStorage.getItem("token");
    },
  },
  created() {
    if (this.personType) {
      this.getContacts();
    } else {
    }
  },
  methods: {
    // 个人信息查询
    async getContacts() {
      const res = await getContact(this.personData.personCode);
      if (res.code === 200 && res.data !== null) {
        this.ruleForm = res.data;
      } else {
      }
    },
    // 修改
    async submitForm() {
      Object.assign(this.ruleForm, {
        Authorization: this.Authorization,
        personCode: this.personData.personCode
          ? this.personData.personCode
          : this.saveId,
      });
      const res = await addContact(this.ruleForm);
      if (res.code === 200) {
        return 200;
      }
    },
    cancelItem() {
      this.$router.go(-1);
    },
  },
  mounted() {
    if (this.personType) {
      document.getElementsByClassName("contact")[0].style.height =
        document.body.clientHeight - 374 + "px";
    }
  },
};
</script>

<style scoped lang="less">
/deep/ .el-input__inner {
  width: 470px;
}
.contact {
  // height: 560px;
}
.bottom {
  border-top: 1px solid #dbdbdb;
  background: #fff;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  .el-button {
    margin: 0 20px;
  }
}
</style>
