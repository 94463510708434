<template>
  <div class="educationHistory">
    <div class="index">
      <div style="margin-bottom: 10px">
        <el-button type="primary" @click="dialogFormVisible = true"
          >+添加教育经历</el-button
        >
      </div>
      <el-table
        class="ly_table"
        :cell-style="{ 'text-align': 'center' }"
        :header-cell-style="{
          background: '#F5F6F7',
          color: '#363E4D',
          'text-align': 'center',
        }"
        :data="form2"
      >
        <template slot="empty">
          <img src="@/assets/img/table.png" alt="没有数据" srcset />
        </template>
        <el-table-column prop="startDate,endDate" label="时间">
          <template slot-scope="scope"
            >{{ scope.row.startDate }}-{{ scope.row.endDate }}</template
          >
        </el-table-column>
        <el-table-column prop="schoolName" label="学校名称"></el-table-column>
        <el-table-column prop="education" label="学历"></el-table-column>
        <el-table-column prop="major" label="就读专业"></el-table-column>
        <el-table-column
          prop="educationalMode"
          label="教育方式"
        ></el-table-column>
        <el-table-column prop="schoolTag" label="学校标签"></el-table-column>
        <el-table-column label="操作" width="120" align="center">
          <template slot-scope="scope">
            <button-table
              @buttonClick="openEdit(scope.row, scope.$index)"
              content="编辑"
              icon="jr-iconjr-icon-edit"
            />
            <button-table
              @buttonClick="deleteEx(scope.row, scope.$index)"
              content="删除"
              icon="jr-iconjr-icon-delect"
            />
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[3, 5, 10]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
      <el-dialog
        title="添加教育经历"
        :visible.sync="dialogFormVisible"
        center
        @close="handleClose"
      >
        <el-form :model="form" :rules="formRules" ref="ruleForm">
          <el-form-item label="开始时间:" label-width="120px" prop="startDate">
            <el-date-picker
              v-model="form.startDate"
              align="right"
              type="month"
              value-format="yyyy-MM"
              placeholder="选择日期"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="结束时间:" label-width="120px" prop="endDate">
            <el-date-picker
              v-model="form.endDate"
              align="right"
              type="month"
              value-format="yyyy-MM"
              placeholder="选择日期"
              :picker-options="pickerOptions"
              @change="disableLeaveDate"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="学校名称:" label-width="120px" prop="schoolName">
            <el-input v-model="form.schoolName" style="width: 430px"></el-input>
          </el-form-item>
          <el-form-item label="学历:" label-width="120px" prop="education">
            <el-select
              v-model="form.education"
              placeholder="请选择学历"
              :clearable="true"
            >
              <el-option label="中专" value="中专"></el-option>
              <el-option label="大专" value="大专"></el-option>
              <el-option label="本科" value="本科"></el-option>
              <el-option label="研究生" value="研究生"></el-option>
              <el-option label="硕士" value="硕士"></el-option>
              <el-option label="博士" value="博士"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="就读专业:" label-width="120px" prop="major">
            <el-input v-model="form.major" style="width: 430px"></el-input>
          </el-form-item>
          <el-form-item
            label="教育方式:"
            label-width="120px"
            prop="educationalMode"
          >
            <el-radio-group v-model="form.educationalMode">
              <el-radio label="统招"></el-radio>
              <el-radio label="非统招"></el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="学校标签:" label-width="120px">
            <el-radio-group v-model="form.schoolTag">
              <el-radio label="985"></el-radio>
              <el-radio label="211"></el-radio>
              <el-radio label="其它"></el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="addPersonEx('ruleForm')"
            >确 定</el-button
          >
          <el-button @click="dialogFormVisible = false">取 消</el-button>
        </div>
      </el-dialog>
      <el-dialog
        title="编辑教育经历"
        :visible.sync="dialogFormVisible1"
        center
        @close="handleClose"
      >
        <el-form :model="editform" :rules="formRules" ref="editruleForm">
          <el-form-item label="开始时间:" label-width="120px" prop="startDate">
            <el-date-picker
              v-model="editform.startDate"
              align="right"
              type="month"
              value-format="yyyy-MM"
              placeholder="选择日期"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="结束时间:" label-width="120px" prop="endDate">
            <el-date-picker
              v-model="editform.endDate"
              align="right"
              type="month"
              value-format="yyyy-MM"
              placeholder="选择日期"
              :picker-options="pickerOptions"
              @change="disableLeaveDateEdit"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="学校名称:" label-width="120px" prop="schoolName">
            <el-input
              v-model="editform.schoolName"
              style="width: 430px"
            ></el-input>
          </el-form-item>
          <el-form-item label="学历:" label-width="120px" prop="education">
            <el-select v-model="editform.education" placeholder="请选择学历">
              <el-option label="中专" value="中专"></el-option>
              <el-option label="大专" value="大专"></el-option>
              <el-option label="本科" value="本科"></el-option>
              <el-option label="研究生" value="研究生"></el-option>
              <el-option label="硕士" value="硕士"></el-option>
              <el-option label="博士" value="博士"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="就读专业:" label-width="120px" prop="major">
            <el-input v-model="editform.major" style="width: 430px"></el-input>
          </el-form-item>
          <el-form-item
            label="教育方式:"
            label-width="120px"
            prop="educationalMode"
          >
            <el-radio-group v-model="editform.educationalMode">
              <el-radio label="统招"></el-radio>
              <el-radio label="非统招"></el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="学校标签:" label-width="120px">
            <el-radio-group v-model="editform.schoolTag">
              <el-radio label="985"></el-radio>
              <el-radio label="211"></el-radio>
              <el-radio label="其它"></el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="editEx('editruleForm')"
            >确 定</el-button
          >
          <el-button @click="cancelEx('editruleForm')">取 消</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- <div class="bottom" v-if="personType">
        <el-button @click="cancelItem" type="primary">确定</el-button>
        <el-button type="info" @click="cancelItem">取消</el-button>
    </div>-->
  </div>
</template>

<script>
import ButtonTable from "../../../../components/ButtonTable.vue";
import {
  getEducation,
  editEducation,
  addEducation,
  deleteEducation,
} from "@/api/talent.js";
import { pagination } from "../../../../mixins/pagination";
export default {
  mixins: [pagination],
  components: {
    ButtonTable,
  },
  data() {
    return {
      idnex: "",
      form: {
        startDate: "", //开始时间
        endDate: "", //结束时间
        schoolName: "", //学校名称
        education: "", //学历
        major: "", //就读专业
        educationalMode: "", //教育方式
        schoolTag: "", //学校标签
        createName: "", //创建人名字
        createTime: "", //创建时间
        updateName: "", //修改人名字
        updateTime: "", //修改时间
      },
      formRules: {
        startDate: [
          { required: true, message: "请输入开始时间", trigger: "blur" },
        ],
        endDate: [
          { required: true, message: "请输入结束时间", trigger: "blur" },
        ],
        schoolName: [
          { required: true, message: "请输入学校名称", trigger: "blur" },
        ],
        education: [{ required: true, message: "请输入学历", trigger: "blur" }],
        major: [{ required: true, message: "请输入就读专业", trigger: "blur" }],
        educationalMode: [
          { required: true, message: "请输入教育方式", trigger: "blur" },
        ],
      },
      queryInfo: {
        personCode: "", //人才编号
        pageSize: 10,
        pageNum: 1,
      },
      // total: 0,
      form2: [],
      dialogFormVisible: false,
      dialogFormVisible1: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      editform: "",
    };
  },
  computed: {
    Authorization() {
      return sessionStorage.getItem("token");
    },
  },
  props: {
    // 编辑数据
    personData: {
      type: Object,
    },
    // 0 新增 1 编辑
    personType: {
      type: Number,
    },
    // 新增唯一id
    saveId: {
      type: String,
    },
  },
  created() {
    if (this.personType || this.saveId) {
      this.search();
    }
  },
  mounted() {
    if (this.personType) {
      document.getElementsByClassName("educationHistory")[0].style.height =
        document.body.clientHeight - 320 + "px";
    }
  },
  methods: {
    // 如果开始时间大于或等于结束时间，就提示并且清空结束的框----新增
    disableLeaveDate(time) {
      if (!this.form.startDate) {
        return false;
      }
      // 开始时间
      const startDate = new Date(this.form.startDate).getTime();
      // 离职时间
      const endDate = new Date(time).getTime();
      if (startDate >= endDate) {
        this.$message.warning("结束时间不能大于或等于开始时间");
        this.form.endDate = "";
      }
    },
    // 如果开始时间大于或等于结束时间，就提示并且清空结束的框----编辑
    disableLeaveDateEdit(time) {
      if (!this.editform.startDate) {
        return false;
      }
      // 开始时间
      const startDate = new Date(this.editform.startDate).getTime();
      // 离职时间
      const endDate = new Date(time).getTime();
      if (startDate >= endDate) {
        this.$message.warning("结束时间不能大于或等于开始时间");
        this.editform.endDate = "";
      }
    },
    // 清空表单
    deleteForm() {
      this.form = {
        startDate: "", //开始时间
        endDate: "", //结束时间
        schoolName: "", //学校名称
        education: "", //学历
        major: "", //就读专业
        educationalMode: "", //教育方式
        schoolTag: "", //学校标签
        createName: "", //创建人名字
        createTime: "", //创建时间
        updateName: "", //修改人名字
        updateTime: "", //修改时间
      };
      this.dialogFormVisible = false;
      this.dialogFormVisible1 = false;
    },
    // 查询
    async search() {
      if (this.personType || this.saveId) {
        this.queryInfo.personCode = this.personData.personCode
          ? this.personData.personCode
          : this.saveId;
      }
      const res = await getEducation(this.queryInfo);
      if (res.code === 200) {
        this.form2 = res.data.list;
        this.total = res.data.total;
      }
    },
    // 添加工作经历
    addPersonEx(formName) {
      if (this.personType || this.saveId) {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            Object.assign(this.form, {
              Authorization: this.Authorization,
              personCode: this.personData.personCode
                ? this.personData.personCode
                : this.saveId,
              personType: "企业机构人才",
            });
            const res = await addEducation(this.form);
            if (res.code === 200) {
              this.dialogFormVisible = false;
              this.deleteForm();
              this.search();
            }
          }
        });
      } else {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            var data = this.form;
            this.form2.push(data);
            this.deleteForm();
          }
        });
      }
    },
    // 删除工作经历
    async deleteEx(data, index) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示").then(
        async () => {
          if (this.personType || this.saveId) {
            const deleteForm = {
              id: data.id,
              personCode: this.personData.personCode
                ? this.personData.personCode
                : this.saveId,
              personType: "企业机构人才",
            };
            const res = await deleteEducation(deleteForm);
            if (res.code == 200) {
              this.search();
            }
          } else {
            this.form2.splice(index, 1);
          }
        }
      );
    },
    // 打开编辑界面
    openEdit(data, index1) {
      this.index = index1;
      this.dialogFormVisible1 = true;
      this.editform = JSON.parse(JSON.stringify(data));
      Object.assign(this.editform, {
        Authorization: this.Authorization,
      });
    },
    // 修改工作经历
    editEx(formName) {
      if (this.personType || this.saveId) {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            const res = await editEducation(this.editform);
            if (res.code === 200) {
              this.deleteForm();
              this.search();
            }
          }
        });
      } else {
        this.form2[this.index] = this.editform;
        this.dialogFormVisible1 = false;
      }
    },
    cancelEx(formName) {
      this.dialogFormVisible1 = false;
      this.$refs[formName].resetFields();
    },
    handleClose() {
      this.deleteForm();
    },
    cancelItem() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped lang="less">
.el-input__inner {
  width: 200px;
}
.el-button {
  padding: 12px 15px;
}
/deep/.el-dialog {
  width: 645px;
}
.index {
  // height: 560px;
  overflow: auto;
}
.bottom {
  border-top: 1px solid #dbdbdb;
  background: #fff;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  .el-button {
    padding: 8px 10px;
    margin: 0 20px;
  }
}
</style>
