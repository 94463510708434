<template>
  <div class="projectHistory">
    <div class="index">
      <div style="margin-bottom: 10px">
        <el-button type="primary" @click="dialogFormVisible = true"
          >+添加项目经历</el-button
        >
      </div>
      <el-table
        class="ly_table"
        :cell-style="{ 'text-align': 'center' }"
        :header-cell-style="{
          background: '#F5F6F7',
          color: '#363E4D',
          'text-align': 'center',
        }"
        :data="form2"
      >
        <template slot="empty">
          <img src="@/assets/img/table.png" alt="没有数据" srcset />
        </template>
        <el-table-column prop="startTime,endTime" label="项目时间">
          <template slot-scope="scope"
            >{{ scope.row.startTime }}-{{ scope.row.endTime }}</template
          >
        </el-table-column>
        <el-table-column prop="projectName" label="项目名称"></el-table-column>
        <el-table-column
          prop="talentIndustry"
          label="行业类别"
          v-if="personType"
        ></el-table-column>
        <el-table-column prop="talentIndustry" label="行业类别" v-else>
          <template slot-scope="scope">
            <span v-for="(item, index) in scope.row.talentIndustry" :key="index"
              >{{ item }}&nbsp;&nbsp;</span
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="fieldId"
          label="领域类别"
          v-if="personType"
        ></el-table-column>
        <el-table-column prop="fieldId" label="领域类别" v-else>
          <template slot-scope="scope">
            <span v-for="(item, index) in scope.row.fieldId" :key="index"
              >{{ item }}&nbsp;&nbsp;</span
            >
          </template>
        </el-table-column>
        <el-table-column prop="projectIntro" label="项目简介">
          <template slot-scope="scope">
            <div>
              <el-popover
                placement="right"
                width="300"
                trigger="hover"
                :content="scope.row.projectIntro"
              >
                <div slot="reference">
                  <div
                    v-if="
                      scope.row.projectIntro !== null &&
                      scope.row.projectIntro !== ''
                    "
                  >
                    {{ scope.row.projectIntro.substring(0, 50) }}
                    <span v-if="scope.row.projectIntro.length > 50">...</span>
                  </div>
                </div>
              </el-popover>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="projectFunction"
          label="项目职务"
        ></el-table-column>
        <el-table-column prop="projectAchievement" label="项目业绩">
          <template slot-scope="scope">
            <div>
              <el-popover
                placement="right"
                width="300"
                trigger="hover"
                :content="scope.row.projectAchievement"
              >
                <div slot="reference">
                  <div
                    v-if="
                      scope.row.projectAchievement !== null &&
                      scope.row.projectAchievement !== ''
                    "
                  >
                    {{ scope.row.projectAchievement.substring(0, 50) }}
                    <span v-if="scope.row.projectAchievement.length > 50"
                      >...</span
                    >
                  </div>
                </div>
              </el-popover>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="120" align="center">
          <template slot-scope="scope">
            <button-table
              @buttonClick="openEdit(scope.row, scope.$index)"
              content="编辑"
              icon="jr-iconjr-icon-edit"
            />
            <button-table
              @buttonClick="deleteProject(scope.row, scope.$index)"
              content="删除"
              icon="jr-iconjr-icon-delect"
            />
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[3, 5, 10]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
      <el-dialog
        title="添加项目经历"
        :visible.sync="dialogFormVisible"
        @close="handleClose"
        center
        width="50%"
      >
        <el-form :model="form" :rules="formRules" ref="ruleForm" v-if="isShow">
          <el-form-item label="开始时间:" label-width="120px" prop="startTime">
            <el-date-picker
              v-model="form.startTime"
              align="right"
              type="month"
              placeholder="选择日期"
              value-format="yyyy-MM"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="结束时间:" label-width="120px" prop="endTime">
            <el-date-picker
              v-model="form.endTime"
              align="right"
              type="month"
              value-format="yyyy-MM"
              placeholder="选择日期"
              :picker-options="pickerOptions"
              @change="disableLeaveDate"
            ></el-date-picker>
          </el-form-item>
          <el-form-item
            label="所在公司:"
            label-width="120px"
            prop="companyName"
          >
            <el-input
              v-model="form.companyName"
              style="width: 430px"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="项目名称:"
            label-width="120px"
            prop="projectName"
          >
            <el-input
              v-model="form.projectName"
              style="width: 430px"
            ></el-input>
          </el-form-item>
          <el-form-item label="行业类别:" label-width="120px">
            <dic-checkbox-group
              :code.sync="form.talentIndustry"
              tag="行业"
              @updateCode="updateCode"
              ref="induce1"
            />
          </el-form-item>
          <el-form-item label="领域类别:" label-width="120px">
            <dic-checkbox-group
              :code.sync="form.fieldId"
              tag="领域"
              @updateCode="updateCode"
              ref="induce"
            />
          </el-form-item>
          <el-form-item label="项目简介:" label-width="120px">
            <el-input
              v-model="form.projectIntro"
              type="textarea"
              :autosize="{ maxRows: 20 }"
            ></el-input>
          </el-form-item>
          <el-form-item label="项目职务:" label-width="120px">
            <el-input
              v-model="form.projectFunction"
              style="width: 430px"
            ></el-input>
          </el-form-item>
          <el-form-item label="项目职责:" label-width="120px">
            <el-input v-model="form.projectDuty" type="textarea"></el-input>
          </el-form-item>
          <el-form-item label="项目业绩:" label-width="120px">
            <el-input
              v-model="form.projectAchievement"
              type="textarea"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="addPersonEx('ruleForm')"
            >确 定</el-button
          >
          <el-button @click="dialogFormVisible = false">取 消</el-button>
        </div>
      </el-dialog>
      <el-dialog
        title="编辑项目经历"
        :visible.sync="dialogFormVisible1"
        @close="handleClose"
        center
      >
        <el-form
          :model="editform"
          :rules="formRules"
          ref="editruleForm"
          v-if="isShow"
        >
          <el-form-item label="开始时间:" label-width="120px" prop="startTime">
            <el-date-picker
              v-model="editform.startTime"
              align="right"
              type="month"
              value-format="yyyy-MM"
              placeholder="选择日期"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="结束时间:" label-width="120px" prop="endTime">
            <el-date-picker
              v-model="editform.endTime"
              align="right"
              type="month"
              value-format="yyyy-MM"
              placeholder="选择日期"
              :picker-options="pickerOptions"
              @change="disableLeaveDateEdit"
            ></el-date-picker>
          </el-form-item>
          <el-form-item
            label="所在公司:"
            label-width="120px"
            prop="companyName"
          >
            <el-input
              v-model="editform.companyName"
              style="width: 430px"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="项目名称:"
            label-width="120px"
            prop="projectName"
          >
            <el-input
              v-model="editform.projectName"
              style="width: 430px"
            ></el-input>
          </el-form-item>
          <el-form-item label="行业类别:" label-width="120px">
            <dic-checkbox-group
              :code.sync="editform.talentIndustry"
              tag="行业"
              @updateCode="updateCode1"
              ref="induce1"
            />
          </el-form-item>
          <el-form-item label="领域类别:" label-width="120px">
            <dic-checkbox-group
              :code.sync="editform.fieldId"
              tag="领域"
              @updateCode="updateCode1"
              ref="induce"
            />
          </el-form-item>
          <el-form-item label="项目简介:" label-width="120px">
            <el-input
              v-model="editform.projectIntro"
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 20 }"
            ></el-input>
          </el-form-item>
          <el-form-item label="项目职务:" label-width="120px">
            <el-input
              v-model="editform.projectFunction"
              style="width: 430px"
            ></el-input>
          </el-form-item>
          <el-form-item label="项目职责:" label-width="120px">
            <el-input v-model="editform.projectDuty" type="textarea"></el-input>
          </el-form-item>
          <el-form-item label="项目业绩:" label-width="120px">
            <el-input
              v-model="editform.projectAchievement"
              type="textarea"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="editEx('editruleForm')"
            >确 定</el-button
          >
          <el-button @click="cancelEx('editruleForm')">取 消</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- <div class="bottom" v-if="personType">
        <el-button @click="cancelItem" type="primary">确定</el-button>
        <el-button type="info" @click="cancelItem">取消</el-button>
    </div>-->
  </div>
</template>

<script>
import DicCheckboxGroupTalent from "@/components/DicCheckboxGroupTalent.vue";
import DicCheckboxGroup from "@/components/DicCheckboxGroup.vue";
import DicRadioGroup from "@/components/DicRadioGroup.vue";
import ButtonTable from "../../../../components/ButtonTable.vue";
import {
  getProject,
  addProject,
  editProject,
  deleteProject,
} from "@/api/talent.js";
import { pagination } from "../../../../mixins/pagination";
export default {
  mixins: [pagination],
  components: {
    ButtonTable,
    DicRadioGroup,
    DicCheckboxGroupTalent,
    DicCheckboxGroup,
  },
  data() {
    return {
      isShow: false,
      form: {
        startTime: "", //开始时间
        endTime: "", //结束时间
        projectDuty: "", //项目职责
        projectName: "", //项目名称
        projectIntro: "", //项目简介
        projectFunction: "", //项目职务
        projectAchievement: "", //项目业绩
        createName: "", //创建人名字
        createTime: "", //创建时间
        updateName: "", //修改人
        updateTime: "", //修改时间
        companyName: "", //所在公司
        talentIndustry: [], //行业类别
        fieldId: [], //领域类别
        talentIndustryChild: [], //行业类别
        fieldChildrenId: [], //领域类别
      },
      formRules: {
        companyName: [
          { required: true, message: "请输入公司名称", trigger: "blur" },
        ],
        projectName: [
          { required: true, message: "请输入项目名称", trigger: "blur" },
        ],
        startTime: [{ required: true, message: "开始时间", trigger: "blur" }],
        endTime: [{ required: true, message: "结束时间", trigger: "blur" }],
        // position: [{ required: true, message: "所在职务", trigger: "blur" }],
      },
      queryInfo: {
        personCode: "", //人才编号
        pageSize: 10,
        pageNum: 1,
      },
      // total: 0,
      index: 0,
      form2: [],
      dialogFormVisible: false,
      dialogFormVisible1: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      editform: {
        startTime: "", //开始时间
        endTime: "", //结束时间
        projectDuty: "", //项目职责
        projectName: "", //项目名称
        projectIntro: "", //项目简介
        projectFunction: "", //项目职务
        projectAchievement: "", //项目业绩
        createName: "", //创建人名字
        createTime: "", //创建时间
        updateName: "", //修改人
        updateTime: "", //修改时间
        companyName: "", //所在公司
        talentIndustry: [], //行业类别
        fieldId: [], //领域类别
        talentIndustryChild: [], //行业类别
        fieldChildrenId: [], //领域类别
      },
    };
  },
  computed: {
    Authorization() {
      return sessionStorage.getItem("token");
    },
  },
  props: {
    // 编辑的数据
    personData: {
      type: Object,
    },
    // 0 新增 1 编辑
    personType: {
      type: Number,
    },
    // 新增唯一id
    saveId: {
      type: String,
    },
  },
  created() {
    if (this.personType || this.saveId) {
      this.search();
    }
    setTimeout(() => {
      if (this.enterpriseType && this.enterpriseInfo.companyCode) {
        this.isShow = true;
      } else if (!this.enterpriseType) {
        this.isShow = true;
      }
    }, 500);
  },
  mounted() {
    if (this.personType) {
      document.getElementsByClassName("projectHistory")[0].style.height =
        document.body.clientHeight - 320 + "px";
    }
  },
  methods: {
    // 如果开始时间大于或等于结束时间，就提示并且清空结束的框----新增
    disableLeaveDate(time) {
      if (!this.form.startTime) {
        return false;
      }
      // 开始时间
      const startTime = new Date(this.form.startTime).getTime();
      // 离职时间
      const endTime = new Date(time).getTime();
      if (startTime >= endTime) {
        this.$message.warning("结束时间不能大于或等于开始时间");
        this.form.endTime = "";
      }
    },
    // 如果开始时间大于或等于结束时间，就提示并且清空结束的框----编辑
    disableLeaveDateEdit(time) {
      if (!this.editform.startTime) {
        return false;
      }
      // 开始时间
      const startTime = new Date(this.editform.startTime).getTime();
      // 离职时间
      const endTime = new Date(time).getTime();
      if (startTime >= endTime) {
        this.$message.warning("结束时间不能大于或等于开始时间");
        this.editform.endTime = "";
      }
    },
    // 清空表单
    deleteForm() {
      (this.form = {
        startTime: "", //开始时间
        endTime: "", //结束时间
        projectDuty: "", //项目职责
        projectName: "", //项目名称
        projectIntro: "", //项目简介
        projectFunction: "", //项目职务
        projectAchievement: "", //项目业绩
        createName: "", //创建人名字
        createTime: "", //创建时间
        updateName: "", //修改人
        updateTime: "", //修改时间
        companyName: "", //所在公司
        talentIndustry: [], //行业类别
        fieldId: [], //领域类别
        talentIndustryChild: [], //行业类别
        fieldChildrenId: [], //领域类别
      }),
        (this.dialogFormVisible = false);
      this.dialogFormVisible1 = false;
    },
    deleteForm1() {
      (this.editform = {
        startTime: "", //开始时间
        endTime: "", //结束时间
        projectDuty: "", //项目职责
        projectName: "", //项目名称
        projectIntro: "", //项目简介
        projectFunction: "", //项目职务
        projectAchievement: "", //项目业绩
        createName: "", //创建人名字
        createTime: "", //创建时间
        updateName: "", //修改人
        updateTime: "", //修改时间
        companyName: "", //所在公司
        talentIndustry: [], //行业类别
        fieldId: [], //领域类别
        talentIndustryChild: [], //行业类别
        fieldChildrenId: [], //领域类别
      }),
        (this.dialogFormVisible = false);
      this.dialogFormVisible1 = false;
    },
    // 查询
    async search() {
      if (this.personType || this.saveId) {
        this.queryInfo.personCode = this.personData.personCode
          ? this.personData.personCode
          : this.saveId;
      }
      const res = await getProject(this.queryInfo);
      if (res.code === 200) {
        this.form2 = res.data.list;
        this.total = res.data.total;
      }
    },
    // 添加项目经历
    addPersonEx(formName) {
      if (this.personType || this.saveId) {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            Object.assign(this.form, {
              Authorization: this.Authorization,
              personCode: this.personData.personCode
                ? this.personData.personCode
                : this.saveId,
              personType: "企业机构人才",
            });
            if (this.form.fieldChildrenId) {
              this.form.fieldId = this.form.fieldId.concat(
                this.form.fieldChildrenId
              );
            }
            if (this.form.talentIndustryChild) {
              this.form.talentIndustry = this.form.talentIndustry.concat(
                this.form.talentIndustryChild
              );
            }
            const res = await addProject(this.form);
            if (res.code === 200) {
              this.dialogFormVisible = false;
              this.$refs.induce.clear();
              this.$refs.induce1.clear();
              this.deleteForm();
              this.search();
            }
          }
        });
      } else {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            if (this.form.fieldChildrenId) {
              this.form.fieldId = this.form.fieldId.concat(
                this.form.fieldChildrenId
              );
            }
            if (this.form.talentIndustryChild) {
              this.form.talentIndustry = this.form.talentIndustry.concat(
                this.form.talentIndustryChild
              );
            }
            this.form2.push(this.form);
            this.deleteForm();
          }
        });
      }
    },
    // 删除工作经历
    async deleteProject(data, index) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示").then(
        async () => {
          if (this.personType || this.saveId) {
            const deleteForm = {
              id: data.id,
              personCode: this.personData.personCode
                ? this.personData.personCode
                : this.saveId,
              personType: "企业机构人才",
            };
            const res = await deleteProject(deleteForm);
            if (res.code == 200) {
              this.search();
            }
          } else {
            this.form2.splice(index, 1);
          }
        }
      );
    },
    // 打开编辑界面
    openEdit(data, index1) {
      if (this.$refs.induce) {
        this.$refs.induce.getDicData();
      }
      if (this.$refs.induce1) {
        this.$refs.induce1.getDicData();
      }
      this.index = index1;
      this.dialogFormVisible1 = true;
      Object.assign(this.editform, data);
      Object.assign(this.editform, {
        Authorization: this.Authorization,
      });
      if (this.personType || this.saveId) {
        if (this.editform.talentIndustry) {
          this.editform.talentIndustry =
            this.editform.talentIndustry.split(",");
        } else {
          this.editform.talentIndustry = [];
        }
        if (this.editform.fieldId) {
          this.editform.fieldId = this.editform.fieldId.split(",");
        } else {
          this.editform.fieldId = [];
        }
      }
    },
    // 修改工作经历
    editEx(formName) {
      if (this.personType || this.saveId) {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            if (this.editform.fieldChildrenId) {
              this.editform.fieldId = this.editform.fieldId.concat(
                this.editform.fieldChildrenId
              );
            }
            if (this.editform.talentIndustryChild) {
              this.editform.talentIndustry =
                this.editform.talentIndustry.concat(
                  this.editform.talentIndustryChild
                );
            }
            const res = await editProject(this.editform);
            if (res.code === 200) {
              this.$refs.induce.clear();
              this.$refs.induce1.clear();
              this.deleteForm();
              this.search();
            }
          }
        });
      } else {
        if (this.editform.fieldChildrenId) {
          this.editform.fieldId = this.editform.fieldId.concat(
            this.editform.fieldChildrenId
          );
        }
        if (this.editform.talentIndustryChild) {
          this.editform.talentIndustry = this.editform.talentIndustry.concat(
            this.editform.talentIndustryChild
          );
        }
        this.form2[this.index] = this.editform;
        this.deleteForm1();
      }
    },
    cancelEx(formName) {
      this.dialogFormVisible1 = false;
      this.deleteForm();
    },
    updateCode(val, tag) {
      switch (tag) {
        case "领域":
          this.form.fieldChildrenId = val;
          break;

        case "行业":
          this.form.talentIndustryChild = val;
          break;

        default:
          break;
      }
    },
    updateCode1(val, tag) {
      switch (tag) {
        case "领域":
          this.editform.fieldChildrenId = val;
          break;

        case "行业":
          this.editform.talentIndustryChild = val;
          break;

        default:
          break;
      }
    },
    handleClose() {
      this.deleteForm();
      this.$refs.induce.clear();
      this.$refs.induce1.clear();
    },
    cancelItem() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped lang="less">
.el-input__inner {
  width: 200px;
}
.el-button {
  padding: 12px 15px;
}
/deep/.el-dialog {
  width: 645px;
}
.index {
  // height: 560px;
  overflow: auto;
}
.bottom {
  border-top: 1px solid #dbdbdb;
  background: #fff;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  .el-button {
    padding: 8px 10px;
    margin: 0 20px;
  }
}
</style>
