<template>
  <div>
    <div class="personMessage">
      <el-form
        :model="editTalentInfo"
        :rules="ruleFormrules"
        ref="editTalentInfo"
        v-if="isShow"
        label-width="100px"
      >
        <div class="topTip">
          <span class="yuanjiao"></span>
          <span style="color: #00a1e9; font-weight: 600" class="basicMessage"
            >基本信息</span
          >
        </div>
        <el-row style="margin-top: 15px">
          <el-col :span="4">
            <el-form-item label-width="100px" label="性别:" prop="sex">
              <el-radio-group v-model="editTalentInfo.sex">
                <el-radio label="男">男</el-radio>
                <el-radio label="女">女</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item label-width="100px" label="年龄:" prop="age">
              <el-input
                v-model="editTalentInfo.age"
                style="width: 205px"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- <el-form-item label-width="100px" label="人才序号:" prop="serialNumber">
          <el-input v-model="editTalentInfo.serialNumber" style="width: 159px"></el-input>
        </el-form-item> -->
        <el-form-item label="人才链接:" label-width="100px">
          <!-- <el-input
            v-model="editTalentInfo.personUrl"
            style="width: 470px"
          ></el-input> -->
          <el-link
            :href="editTalentInfo.personUrl"
            target="_blank"
            :underline="false"
            >{{ editTalentInfo.personUrl }}</el-link
          >
          <i
            class="jr-iconjr-icon-edit1"
            style="margin-left: 24px"
            @click="isLinkShow = true"
          ></i>
          <div class="input_button" v-if="isLinkShow">
            <el-input v-model="editTalentInfo.personUrl" style="width: 470px" />
            <el-button
              type="primary"
              style="height: 40px; margin-left: 24px"
              @click="isLinkShow = false"
              >保存</el-button
            >
          </div>
        </el-form-item>
        <el-form-item
          label="工作经验:"
          style="margin-bottom: 20px"
          label-width="100px"
          prop="workingYears"
        >
          <dic-radio-group
            :code.sync="editTalentInfo.workingYears"
            type-code="005"
          />
        </el-form-item>
        <el-form-item
          label="最高学历:"
          label-width="100px"
          prop="educationalBackground"
        >
          <el-select
            v-model="editTalentInfo.educationalBackground"
            placeholder="请选择学历"
            :clearable="true"
          >
            <el-option label="中专" value="中专"></el-option>
            <el-option label="大专" value="大专"></el-option>
            <el-option label="本科" value="本科"></el-option>
            <el-option label="研究生" value="研究生"></el-option>
            <el-option label="硕士" value="硕士"></el-option>
            <el-option label="博士" value="博士"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="合作状态:" label-width="100px">
          <dic-radio-group
            :code.sync="editTalentInfo.cooperationStatus"
            type-code="001"
          />
        </el-form-item>
        <el-form-item label="所在地区:" label-width="100px">
          <cascader-area
            :country="editTalentInfo.personCountry"
            :province="editTalentInfo.personProvince"
            :city="editTalentInfo.personCity"
            @searchCountry="searchCountry"
            @searchProvince="searchProvince"
            @searchCity="searchCity"
          />
        </el-form-item>
        <el-form-item label="职能类型:" label-width="100px">
          <dic-checkbox-group
            :code.sync="editTalentInfo.positionType"
            type-code="006"
          />
        </el-form-item>
        <div class="topTip">
          <span class="yuanjiao"></span>
          <span style="color: #00a1e9; font-weight: 600" class="basicMessage"
            >职位信息</span
          >
        </div>
        <!-- 删除 -->
        <!-- <el-form-item label="技术类别:" label-width="100px">
          <dic-checkbox-group-talent
            :code.sync="editTalentInfo.currentTechId"
            tag="目前技术"
            @updateCode="updateCode"
          />
        </el-form-item>
        <el-form-item label="技术标签:" label-width="100px" v-if="personType">
          <el-input
            v-model="editTalentInfo.aiTag"
            style="width: 60%"
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 8 }"
          ></el-input>
        </el-form-item>
        <el-form-item label="领域类别:" label-width="100px">
          <dic-checkbox-group-talent
            :code.sync="editTalentInfo.fieldId"
            tag="领域"
            @updateCode="updateCode"
          />
        </el-form-item>
        <el-form-item label="领域标签:" label-width="100px" v-if="personType">
          <el-input
            v-model="editTalentInfo.wisdomTag"
            style="width: 60%"
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 8 }"
          ></el-input>
        </el-form-item> -->
        <!-- <el-form-item
          label="导入工作单位:"
          label-width="100px"
          v-if="!editTalentInfo.comId && !editTalentInfo.comName && personType"
          style="width: 53%"
        >
          <el-input v-model="editTalentInfo.currentCompany"></el-input>
        </el-form-item> -->
        <el-form-item label="当前职位:" label-width="100px">
          <el-input
            v-model="editTalentInfo.position"
            style="width: 50%"
          ></el-input>
        </el-form-item>
        <el-form-item label="职位薪资:" label-width="100px">
          <el-input
            v-model="editTalentInfo.presentSalary"
            style="width: 50%"
          ></el-input>
        </el-form-item>
        <!-- ---------------- -->
        <!-- <el-form-item label="所属企业:" label-width="100px">
          <el-autocomplete
            v-model="editTalentInfo.companyId"
            :fetch-suggestions="querySearchAsync"
            :trigger-on-focus="false"
            placeholder="请输入关键词"
            @input="inputchange"
            value-key="companyFullName"
            value="companyFullName"
            style="width: 50%"
             @select="handleSelect"
          ></el-autocomplete> -->
        <!-- <el-select
            v-model="editTalentInfo.companyId"
            filterable
            remote
            reserve-keyword
            placeholder="请输入关键词"
            :remote-method="remoteMethod"
            :loading="loading"
            style="width: 50%"
            :clearable="true"
            :allow-create="true"
            @change="syncServiceCompanyName"
            :loading-text="loadingText"
          >
            <el-option
              v-for="(item, index) in options"
              :key="index"
              :label="item.companyFullName"
              :value="item.companyCode"
            >
            </el-option>
          </el-select> -->
        <!-- </el-form-item> -->
        <!-- ------------------------ -->
        <el-form-item label="所属企业:" label-width="100px">
          <el-select
            v-model="editTalentInfo.companyId"
            filterable
            remote
            reserve-keyword
            placeholder="请输入关键词"
            :remote-method="remoteMethod"
            :loading="loading"
            style="width: 50%"
            :clearable="true"
            :allow-create="true"
            @change="syncServiceCompanyName"
            :loading-text="loadingText"
          >
            <el-option
              v-for="(item, index) in options"
              :key="index"
              :label="item.companyFullName"
              :value="item.companyCode"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- 新增企业简称，没有字段 -->
        <el-form-item label="企业简称:" label-width="100px">
          <el-input
            v-model="companySimpleName"
            style="width: 50%"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="入职时间:" label-width="100px">
          <el-date-picker
            v-model="editTalentInfo.inductionTime"
            type="month"
            placeholder="选择入职年月"
          ></el-date-picker>
        </el-form-item>
        <!-- 期望职位模块 -->
        <div class="topTip">
          <span class="yuanjiao"></span>
          <span style="color: #00a1e9; font-weight: 600" class="basicMessage"
            >期望职位</span
          >
        </div>
        <el-form-item label="期望地点:" label-width="100px">
          <el-input
            v-model="editTalentInfo.expectedLocation"
            style="width: 50%"
          ></el-input>
        </el-form-item>
        <!-- 期望职务和薪资 -->
        <el-form-item label="期望职务:" label-width="100px">
          <el-input
            v-model="editTalentInfo.expectedPosition"
            style="width: 50%"
          ></el-input>
        </el-form-item>
        <el-form-item label="期望薪资:" label-width="100px">
          <el-input
            v-model="editTalentInfo.salaryExpectation"
            style="width: 50%; height: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注:" label-width="100px">
          <el-input
            v-model="editTalentInfo.note"
            :autosize="{ minRows: 4 }"
            type="textarea"
            style="width: 50%"
          ></el-input>
        </el-form-item>
        <!-- 删除 -->
        <!-- <el-form-item label="技术类别:" label-width="100px">
          <dic-checkbox-group-talent
            :code.sync="editTalentInfo.expectedTechId"
            tag="期望技术"
            @updateCode="updateCode"
          />
        </el-form-item>
        <el-row>
          <el-col :span="10">
            <el-form-item label="勿推荐公司:" label-width="100px">
              <el-input
                v-model="editTalentInfo.unidealCompany"
                type="textarea"
                :autosize="{ minRows: 4, maxRows: 20 }"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="备注:" label-width="100px">
              <el-input
                v-model="editTalentInfo.note"
                :autosize="{ minRows: 4 }"
                type="textarea"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="关键词：" v-if="personType">
          <el-input
            v-model="editTalentInfo.antistop"
            style="width: 700px"
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 8 }"
          ></el-input>
        </el-form-item> -->
      </el-form>
    </div>
    <!-- <div v-if="personType" class="bottom">
      <el-button @click="saveTalents()" type="primary">确定</el-button>
      <el-button type="info" @click="cancelItem">取消</el-button>
    </div> -->
  </div>
</template>

<script>
import DicCheckboxGroupTalent from "@/components/DicCheckboxGroupTalent.vue";
import DicCheckboxGroup from "@/components/DicCheckboxGroup.vue";
import DicRadioGroup from "@/components/DicRadioGroup.vue";
import CascaderArea from "@/components/CascaderArea.vue";
import {
  updateTalents,
  addTalents,
  samrtFind,
  getCompanyName,
} from "@/api/talent";
export default {
  components: {
    CascaderArea,
    DicRadioGroup,
    DicCheckboxGroupTalent,
    DicCheckboxGroup,
  },
  name: "personMessage",
  data() {
    return {
      isLinkShow: false,
      options: [],
      value: [],
      list: [],
      loading: false,
      isShow: false,
      companySimpleName: "", //企业简称
      // 表单校验信息
      imageUrl: "",
      ruleFormrules: {
        name: [{ required: true, message: "请填写姓名", trigger: "change" }],
        sex: [{ required: true, message: "请选择性别", trigger: "change" }],
        age: [{ required: true, message: "请填写年龄", trigger: "change" }],
        // region: [{ required: true, message: "请选择所在城市", trigger: "change" },],
        workingYears: [
          { required: true, message: "请选择工作经验", trigger: "change" },
        ],
        educationalBackground: [
          { required: true, message: "请选择学历", trigger: "change" },
        ],
        // serialNumber: [
        //   { required: true, message: '请填写人才编号', trigger: 'change' },
        // ],
      },
      loadingText: "加载中...", //默认提示
      flag: false, // false 没有查到数据 true就是有数据
      // companyFullName: "", //所属企业
      companyName: "", //所属企业
    };
  },
  props: {
    // 个人信息表单数据
    editTalentInfo: {
      type: Object,
    },
    // 0 新增 1 编辑
    personType: {
      type: Number,
    },
  },
  computed: {
    Authorization() {
      return sessionStorage.getItem("token");
    },
  },
  watch: {
    "editTalentInfo.personCode": {
      handler(newVal) {
        if (this.editTalentInfo && newVal) {
          this.isShow = true;
          // this.options = [
          //   {
          //     companyId: this.editTalentInfo.companyId,
          //     companyName: this.editTalentInfo.companyName,
          //   },
          // ];
        }
      },
      deep: true,
      immediate: true,
    },
    // 监听所属企业被清空的话,也清空企业简称
    "editTalentInfo.companyId": {
      handler(newVal, oldVal) {
        // 如果companyId和companyName都不存在的  companyId 所属企业id companyName 所属企业名称
        if (!newVal && newVal !== oldVal && !this.companyName) {
          this.companySimpleName = "";
        }
      },
    },
  },
  created() {
    // setTimeout(() => {
    //   if (this.personType && this.editTalentInfo.personCode) {
    //     this.isShow = true;
    //     this.options = [{
    //       companyId:this.editTalentInfo.companyId,
    //       companyName:this.editTalentInfo.companyName,
    //     }]
    //   } else if (!this.personType) {
    //     this.isShow = true;
    //   }
    // }, 1000);
    if (!this.personType) {
      this.isShow = true;
    }
    // this.$nextTick(() => {
    //   if (this.personType && this.editTalentInfo.personCode) {
    //     this.isShow = true;
    //     this.options = [{
    //       companyId:this.editTalentInfo.companyId,
    //       companyName:this.editTalentInfo.companyName,
    //     }]
    //   } else if (!this.personType) {
    //     this.isShow = true;
    //   }
    // })
  },
  // mounted() {
  //   // this.options = this.inputchange();
  //   this.loadAll();
  // },
  methods: {
    // 所属企业远程搜索
    // async querySearchAsync(queryString, cb) {
    //   // this.loading = false;
    //   // const companyFullName = queryString;
    //   // const res = await getCompanyName({ companyFullName });
    //   // this.options = res.data;
    //   // if (res.code != 200) {
    //   //   this.loadingText = "加载中...";
    //   // }
    //   // this.loading = false;

    //   console.log(queryString, "queryString");
    //   console.log(cb, "cb");
    //   var options = this.options;
    //   var results = queryString
    //     ? options.filter(this.createStateFilter(queryString))
    //     : options;

    //   clearTimeout(this.timeout);
    //   this.timeout = setTimeout(() => {
    //     cb(results);
    //   }, 3000 * Math.random());
    // },
    // async querySearch(queryString, cb) {
    //   // 假设你有一个获取建议列表的 API
    //   // 你可以根据 queryString 来发送请求到后端接口，获取匹配的建议列表
    //   // 这里只是一个模拟的示例
    //   this.loading = false;
    //   const companyFullName = queryString;
    //   const res = await getCompanyName({ companyFullName });
    //   this.options = res.data;
    //   if (res.code != 200) {
    //     this.loadingText = "加载中...";
    //   }
    //   this.loading = false;

    //   // const suggestions = queryString
    //   //   ? ["建议1", "建议2", "建议" + queryString.substring(0, 1).toUpperCase()]
    //   //   : [];

    //   // 调用回调函数来返回建议列表
    //   // 注意：这里使用的是回调函数的方式，因为 fetch-suggestions 方法期望一个这样的签名
    //   cb(res.data);
    // },
    // createStateFilter(queryString) {
    //   return (state) => {
    //     return (
    //       state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
    //     );
    //   };
    // },
    // async inputchange(item) {
    //   this.loading = false;
    //   const companyFullName = item;
    //   const res = await getCompanyName({ companyFullName });
    //   this.options = res.data;
    //   if (res.code != 200) {
    //     this.loadingText = "加载中...";
    //   }
    //   // if (this.options) {
    //   //   if (this.options.every((item) => item.companyFullName !== query)) {
    //   //     this.options.push({ companyFullName: query, companyCode: query }); // 添加新的选项
    //   //   }
    //   // }

    //   this.loading = false;
    // },
    // async loadAll() {
    //   // const res = await getCompanyName({ companyFullName });
    //   return this.options;
    // },
    handleSelect(item) {
      // 当用户从建议列表中选择一个选项时，这个方法会被调用
      // 你可以在这里处理选择后的逻辑，比如更新你的数据或发送一个请求
      this.state2 = item; // 假设你想把选中的建议设置为输入框的值
    },
    // --------------------------

    // 所属企业查询
    remoteMethod(query) {
      this.loading = true;
      this.loadingText = "加载中...";
      setTimeout(async () => {
        this.loading = false;
        const companyFullName = query;
        const res = await getCompanyName({ companyFullName });
        this.options = res.data;
        if (res.code != 200) {
          this.loadingText = "加载中...";
        }
        if (this.options) {
          if (this.options.every((item) => item.companyFullName !== query)) {
            this.options.push({ companyFullName: query, companyCode: query }); // 添加新的选项
          }
        }

        this.loading = false;
      }, 200);

      // if (query !== "") {
      //   this.loading = true;
      //   this.loadingText = "加载中...";
      //   setTimeout(async () => {
      //     this.loading = false;
      //     const companyFullName = query;
      //     const res = await getCompanyName({ companyFullName });
      //     this.options = res.data;
      //     if (res.code != 200) {
      //       this.loadingText = "加载中...";
      //     }
      //     this.loading = false;
      //   }, 200);
      // } else {
      //   this.options = [];
      // }
    },
    // 根据所属企业来回显企业简称
    syncServiceCompanyName(val) {
      let co = val.substring(0, 2);
      if (co !== "CO") {
        // if (this.options.length < 1) {
        this.companySimpleName = "";
        this.companyName = val;
        this.companyId = null;
      } else {
        this.companyName = null;
        this.companyId = val;
      }
      this.options.forEach((item) => {
        if (item.companyCode == val) {
          this.companySimpleName = item.companyName;
        }

        // 截取输入框值的前两位
        let co = val.substring(0, 2);
        if (co !== "CO") {
          this.companyName = val;
          this.companyId = null;
        } else {
          this.companyName = null;
          this.companyId = val;
        }
      });
    },
    // 保存
    save(res, path) {
      // 将token合并到表单数据中
      Object.assign(this.editTalentInfo, {
        Authorization: this.Authorization,
      });
      this.editTalentInfo.fieldId = this.editTalentInfo.fieldId.concat(
        this.editTalentInfo.fieldChildrenId
      );
      this.editTalentInfo.currentTechId =
        this.editTalentInfo.currentTechId.concat(
          this.editTalentInfo.currentTechChildrenId
        );
      this.editTalentInfo.expectedTechId =
        this.editTalentInfo.expectedTechId.concat(
          this.editTalentInfo.expectedTechChildrenId
        );

      if (
        this.editTalentInfo.fieldId[0] == null ||
        this.editTalentInfo.fieldId[0] == "null"
      ) {
        this.editTalentInfo.fieldId = [];
      }
      if (
        this.editTalentInfo.expectedTechId[0] == null ||
        this.editTalentInfo.expectedTechId[0] == "null"
      ) {
        this.editTalentInfo.expectedTechId = [];
      }
      if (
        this.editTalentInfo.currentTechId[0] == null ||
        this.editTalentInfo.currentTechId[0] == "null"
      ) {
        this.editTalentInfo.currentTechId = [];
      }

      // 新增
      if (!this.personType) {
        // res = await addActivity(allData);
      } else {
        // res = await updateActivityOne(this.enterTalentsInfo);
        // res = await updateActivityOne(allData);
      }
      if (res.code == 200) {
        this.$message.success(`${this.personType ? "编辑" : "新增"}成功`);
        // 赋值新增的唯一id
        if (res.data !== "修改成功" && res.data !== null) {
          this.activityId = res.data;
          this.activeName = path;
        } else {
          this.activeName = path;
        }
        // this.activeName = "second";
        sessionStorage.removeItem("poster");
      } else {
        `${this.personType ? "编辑" : "新增"}失败`;
        // this.activeName = "second";
      }
    },

    // 保存企业人才信息
    async saveTalents() {
      let res;

      // const res = await updateTalents(this.editTalentInfo);
      // if (res.code === 200) {
      //   this.$message.success("编辑成功");
      //   this.$router.go(-1);
      // }
    },
    change() {
      if (this.editTalentInfo) {
        this.ruleForm = this.editTalentInfo;
        this.ruleForm.expectedFieldId =
          this.ruleForm.expectedFieldId.split(",");
        this.ruleForm.currentTechId = this.ruleForm.currentTechId.split(",");
        this.ruleForm.fieldId = this.ruleForm.fieldId.split(",");
        this.ruleForm.expectedTechId = this.ruleForm.expectedTechId.split(",");
      }
    },
    cancelItem() {
      this.$confirm("确认退出吗 ?")
        .then(() => {
          this.$router.go(-1);
        })
        .catch(() => {});
    },
    searchCountry(val) {
      this.editTalentInfo.personCountry = val;
    },
    searchProvince(val) {
      this.editTalentInfo.personProvince = val;
    },
    searchCity(val) {
      if (val != "北京" && val != "天津" && val != "上海" && val != "重庆") {
        this.editTalentInfo.personCity = val;
      }
    },
    updateCode(val, tag) {
      switch (tag) {
        case "领域":
          this.editTalentInfo.fieldChildrenId = val;
          break;

        case "目前技术":
          this.editTalentInfo.currentTechChildrenId = val;
          break;

        case "期望技术":
          this.editTalentInfo.expectedTechChildrenId = val;
          break;

        default:
          break;
      }
    },

    // 保存企业人才信息(之前的方法)
    // async saveTalents() {
    //   Object.assign(this.editTalentInfo, {
    //     Authorization: this.Authorization,
    //   });
    //   this.editTalentInfo.fieldId = this.editTalentInfo.fieldId.concat(
    //     this.editTalentInfo.fieldChildrenId
    //   );
    //   this.editTalentInfo.currentTechId =
    //     this.editTalentInfo.currentTechId.concat(
    //       this.editTalentInfo.currentTechChildrenId
    //     );
    //   this.editTalentInfo.expectedTechId =
    //     this.editTalentInfo.expectedTechId.concat(
    //       this.editTalentInfo.expectedTechChildrenId
    //     );

    //   if (
    //     this.editTalentInfo.fieldId[0] == null ||
    //     this.editTalentInfo.fieldId[0] == "null"
    //   ) {
    //     this.editTalentInfo.fieldId = [];
    //   }
    //   if (
    //     this.editTalentInfo.expectedTechId[0] == null ||
    //     this.editTalentInfo.expectedTechId[0] == "null"
    //   ) {
    //     this.editTalentInfo.expectedTechId = [];
    //   }
    //   if (
    //     this.editTalentInfo.currentTechId[0] == null ||
    //     this.editTalentInfo.currentTechId[0] == "null"
    //   ) {
    //     this.editTalentInfo.currentTechId = [];
    //   }
    //   const res = await updateTalents(this.editTalentInfo);
    //   if (res.code === 200) {
    //     this.$message.success("编辑成功");
    //     this.$router.go(-1);
    //   }
    // },
  },
  mounted() {
    if (this.personType) {
      document.getElementsByClassName("personMessage")[0].style.minHeight =
        document.body.clientHeight - 380 + "px";
    }
  },
};
</script>

<style lang="less" scoped>
.personMessage {
  // 蓝色圆角化
  margin-bottom: 6px;
  // height: 560px;
  overflow: auto;
  .topTip {
    border-bottom: 1px solid #dbdbdb;
    margin-bottom: 20px;
    .yuanjiao {
      border: 2px solid #1e9fff;
      height: 20px;
      background-color: #1e9fff;
      border-radius: 5px;
    }
    .basicMessage {
      display: inline-block;
      margin-left: 10px;
      margin-bottom: 9px;
    }
  }
  .el-select {
    input::-webkit-input-placeholder {
      color: #1f212b;
    }
    //input::-moz-input-placeholder {
    //  color: #1f212b;
    //}
    input::-moz-placeholder {
      color: #1f212b;
    }
    input::-ms-input-placeholder {
      color: #1f212b;
    }
    margin-right: 8px;
  }
  .el-button {
    padding: 12px 38px;
  }
  .el-form-item {
    margin: 0px 0px 10px 8px;
  }
  /deep/ .el-textarea__inner {
    height: 100%;
  }
  /deep/ .el-form-item {
    margin: 0px 0px 15px 0px !important;
  }
}

.bottom {
  border-top: 1px solid #dbdbdb;
  background: #fff;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  .el-button {
    margin: 0 20px;
  }
}
</style>
