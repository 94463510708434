import request from '@/utils/request'

// 查询一览
export function getCompanyList(data) {
  return request({
    url: '/system/company/getCompanyList',
    method: 'post',
    data
  })
}

// 删除
export function deleteCompany(data) {
  return request({
    url: '/system/company/deleteCompany',
    method: 'post',
    data
  })
}

// 新增
export function addCompany(data) {
  return request({
    url: '/system/company/insertCompany',
    method: 'post',
    data
  })
}

// 入库
export function saveMember(data) {
  return request({
    url: '/system/member/saveMember',
    method: 'post',
    data
  })
}

// 查询企业数据
export function getCompanyById(data) {
  return request({
    url: '/system/company/getCompanyById',
    method: 'get',
    params: data
  })
}

// 查询企业数据
export function getPatentInformation(data) {
  return request({
    url: '/system/companyPatent/getPatentInformation',
    method: 'get',
    params: data
  })
}

// 修改
export function updateCompany(data) {
  return request({
    url: '/system/company/updateCompany',
    method: 'post',
    data
  })
}

// 新增团队信息
export function addMember(data) {
  return request({
    url: '/system/member/insertMember',
    method: 'post',
    data
  })
}

// 查询团队信息
export function getMemberById(data) {
  return request({
    url: '/system/member/getMemberById',
    method: 'post',
    data
  })
}

// 编辑团队信息
export function updateMember(data) {
  return request({
    url: '/system/member/updateMember',
    method: 'post',
    data
  })
}

// 删除团队信息
export function deleteMember(data) {
  return request({
    url: '/system/member/deleteMember',
    method: 'get',
    params: data
  })
}

// 企业数据导入
export function uploadImportCompany(data) {
  return request({
    url: "/system/company/importCompany",
    method: "post",
    data
  })
}

// 企业规模查询
export function getCodeByStatus(data) {
  return request({
    url: "/system/code/getCodeByStatus",
    method: "get",
    params: data
  })
}

// 关键词版本查询
export function selectKeywordVersion(data) {
  return request({
    url: "/system/keyword/selectKeywordVersion",
    method: "get",
    params: data
  })
}

// 企业标签新增
export function insertCompanyLabel(data) {
  return request({
    url: "/system/companyLabel/insertCompanyLabel",
    method: "post",
    data
  })
}

// 企业标签编辑
export function updateCompanyLabel(data) {
  return request({
    url: "/system/companyLabel/updateCompanyLabel",
    method: "post",
    data
  })
}

// 企业标签信息详情
export function selectCompanyLabel(data) {
  return request({
    url: "/system/companyLabel/selectCompanyLabelDetail",
    method: "get",
    params: data
  })
}

// 标签内容数据
export function selectLikeLabel(data) {
  return request({
    url: "/system/label/selectLikeLabel",
    method: "get",
    params: data
  })
}

// 查询所有标签组
export function selectAllLabelGroup(data) {
  return request({
    url: "/system/companyLabel/selectAllLabelGroup",
    method: "get",
    params: data
  })
}

// 模糊查询标签组
export function selectLikeGroupLabelName(data) {
  return request({
    url: "/system/companyLabel/selectLikeGroupLabelName",
    method: "get",
    params: data
  })
}
