<template>
  <div class="workHistory">
    <div class="index">
      <div style="margin-bottom: 10px">
        <el-button type="primary" @click="addWorkHistory"
          >+添加工作经历</el-button
        >
      </div>
      <el-table
        class="ly_table"
        :cell-style="{ 'text-align': 'center' }"
        :header-cell-style="{
          background: '#F5F6F7',
          color: '#363E4D',
          'text-align': 'center',
        }"
        :data="form2"
      >
        <el-table-column prop="inductionTime,quitTime" label="工作时间">
          <template slot-scope="scope"
            >{{ scope.row.inductionTime }}-{{ scope.row.quitTime }}</template
          >
        </el-table-column>
        <el-table-column
          prop="talentIndustry"
          label="行业类别"
          v-if="personType"
        ></el-table-column>
        <el-table-column prop="talentIndustry" label="行业类别" v-else>
          <template slot-scope="scope">
            <span v-for="(item, index) in scope.row.talentIndustry" :key="index"
              >{{ item }}&nbsp;&nbsp;</span
            >
          </template>
        </el-table-column>
        <el-table-column prop="companyName" label="公司名称"></el-table-column>
        <el-table-column prop="entIntro" label="公司简介">
          <template
            slot-scope="scope"
            v-if="scope.row.entIntro !== null && scope.row.entIntro !== ''"
          >
            {{ scope.row.entIntro.substring(0, 20) }}
            <span v-if="scope.row.entIntro.length > 20">...</span>
          </template>
        </el-table-column>
        <el-table-column prop="position" label="所在职务"></el-table-column>
        <el-table-column prop="heelerCount" label="下属人数"></el-table-column>
        <el-table-column
          prop="functionType"
          label="职能类型"
          v-if="personType"
        ></el-table-column>
        <el-table-column prop="functionType" label="职能类型" v-else>
          <template slot-scope="scope">
            <span v-for="(item, index) in scope.row.functionType" :key="index"
              >{{ item }}&nbsp;&nbsp;</span
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="companyCountry,companyProvince,row.companyProvince"
          label="所在地区"
        >
          <template slot-scope="scope">
            {{ scope.row.companyCountry ? scope.row.companyCountry : "" }}
            {{ scope.row.companyProvince ? scope.row.companyProvince : "" }}
            {{ scope.row.companyCity ? scope.row.companyCity : "" }}
          </template>
        </el-table-column>
        <el-table-column prop="operatingDuty" label="工作职责和业绩">
          <template slot-scope="scope">
            <div>
              <el-popover
                placement="right"
                width="400"
                trigger="hover"
                :content="scope.row.operatingDuty"
              >
                <div slot="reference">
                  <div
                    v-if="
                      scope.row.operatingDuty !== null &&
                      scope.row.operatingDuty !== ''
                    "
                  >
                    {{ scope.row.operatingDuty.substring(0, 50) }}
                    <span v-if="scope.row.operatingDuty.length > 50">...</span>
                  </div>
                </div>
              </el-popover>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="120" align="center">
          <template slot-scope="scope">
            <button-table
              @buttonClick="openEdit(scope.row, scope.$index)"
              content="编辑"
              icon="jr-iconjr-icon-edit"
            />
            <button-table
              @buttonClick="deleteEx(scope.row, scope.$index)"
              content="删除"
              icon="jr-iconjr-icon-delect"
            />
          </template>
        </el-table-column>
        <template slot="empty">
          <img src="@/assets/img/table.png" alt="没有数据" srcset />
        </template>
      </el-table>
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[3, 5, 10]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
      <el-dialog
        :title="dialogType ? '编辑工作经历' : '添加工作经历'"
        :visible.sync="dialogFormVisible"
        @close="handleClose"
        center
      >
        <el-form
          :model="form"
          :rules="formRules"
          ref="ruleForm"
          label-width="120px"
        >
          <el-form-item label="入职时间:" prop="inductionTime">
            <el-date-picker
              v-model="form.inductionTime"
              align="right"
              type="month"
              placeholder="选择日期"
              value-format="yyyy-MM"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="离职时间:" label-width="120px" prop="quitTime">
            <el-date-picker
              v-model="form.quitTime"
              align="right"
              type="month"
              placeholder="选择日期"
              value-format="yyyy-MM"
              :picker-options="pickerOptions"
              @change="disableLeaveDate"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="行业标签:" label-width="120px">
            <!-- <dic-checkbox-group-talent
            :code.sync="form.talentIndustry"
            tag="行业"
            @updateCode="updateCode"
            ref="editin"
            />-->
            <dic-checkbox-group
              :code.sync="form.talentIndustry"
              tag="行业"
              @updateCode="updateCode"
              ref="editin"
            />
          </el-form-item>
          <el-form-item
            label="公司名称:"
            label-width="120px"
            prop="companyName"
          >
            <el-input
              v-model="form.companyName"
              style="width: 430px"
            ></el-input>
          </el-form-item>
          <el-form-item label="公司简介:" label-width="120px" prop="entIntro">
            <el-input v-model="form.entIntro" style="width: 430px"></el-input>
          </el-form-item>
          <el-row>
            <el-col :span="10">
              <el-form-item
                label="所在职务:"
                label-width="120px"
                prop="position"
              >
                <el-input
                  v-model="form.position"
                  style="width: 172px"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="下属人数:" label-width="120px">
                <el-input
                  v-model="form.heelerCount"
                  style="width: 178px"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="职能类型:" label-width="120px">
            <dic-checkbox-group
              :code.sync="form.functionType"
              type-code="006"
              ref="editin1"
            ></dic-checkbox-group>
          </el-form-item>
          <el-form-item label="所在地区:" label-width="120px">
            <cascader-area
              :country="form.companyCountry"
              :province="form.companyProvince"
              :city="form.companyCity"
              @searchCountry="searchCountry"
              @searchProvince="searchProvince"
              @searchCity="searchCity"
              ref="child"
            />
          </el-form-item>
          <el-form-item label="工作职责和业绩:" label-width="120px">
            <el-input
              v-model="form.operatingDuty"
              style="width: 430px"
              type="textarea"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="addPersonEx('ruleForm')"
            >确 定</el-button
          >
          <el-button @click="dialogFormVisible = false">取 消</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- <div class="bottom" v-if="personType">
        <el-button @click="cancelItem" type="primary">确定</el-button>
        <el-button type="info" @click="cancelItem">取消</el-button>
    </div>-->
  </div>
</template>

<script>
import DicCheckboxGroupTalent from "@/components/DicCheckboxGroupTalent.vue";
import DicCheckboxGroup from "../../../../components/DicCheckboxGroup.vue";
import CascaderArea from "@/components/CascaderArea.vue";
import { getCityAll } from "@/api/common";
import ButtonTable from "../../../../components/ButtonTable.vue";
import DicRadioGroup from "@/components/DicRadioGroup.vue";
import {
  getExperience,
  addExperience,
  deleteExperience,
  editExperience,
} from "@/api/talent.js";
import { pagination } from "../../../../mixins/pagination";
const defaultForm = Object.freeze({
  position: "", //所在职务
  inductionTime: "", //入职时间
  quitTime: "", //离职时间
  talentIndustry: [], //行业标签
  talentIndustryChild: [],
  entIntro: "", //公司简介
  heelerCount: "", //下属人数
  companyName: "", //公司名称
  functionType: [], //职能类型
  companyCountry: "", //所在国家
  companyProvince: "", //所在省份
  companyCity: "", //所在城市
  workIntro: "", //所在地区
  operatingDuty: "", //工作职责和业绩
  createName: "", //创建人名字
  createTime: "", //创建时间
  updateName: "", //修改人名字
  updateTime: "", //修改时间
  id:""
});
export default {
  mixins: [pagination],
  components: {
    ButtonTable,
    CascaderArea,
    DicCheckboxGroupTalent,
    DicRadioGroup,
    DicCheckboxGroup,
  },
  data() {
    return {
      dialogType: 0,
      form: { ...defaultForm },
      index: "",
      formRules: {
        companyName: [
          { required: true, message: "请输入公司名称", trigger: "blur" },
        ],
        entIntro: [
          { required: true, message: "请输入公司简介", trigger: "blur" },
        ],
        inductionTime: [
          { required: true, message: "入职时间", trigger: "blur" },
        ],
        quitTime: [{ required: true, message: "离职时间", trigger: "blur" }],
        position: [{ required: true, message: "所在职务", trigger: "blur" }],
      },
      queryInfo: {
        personnelCode: "", //人才编号
        pageSize: 10,
        pageNum: 1,
      },
      form2: [],
      dialogFormVisible: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
    };
  },
  computed: {
    Authorization() {
      return sessionStorage.getItem("token");
    },
  },
  props: {
    // 编辑的数据
    personData: {
      type: Object,
    },
    // 0 新增 1 编辑
    personType: {
      type: Number,
    },
    // 新增唯一id
    saveId: {
      type: String,
    },
  },
  created() {
    if (this.personType || this.saveId) {
      this.search();
    }
  },
  methods: {
    // 如果离职时间大于或等于入职时间，就提示并且清空离职的框
    disableLeaveDate(time) {
      if (!this.form.inductionTime) {
        return false;
      }
      // 入职时间
      const inductionTime = new Date(this.form.inductionTime).getTime();
      // 离职时间
      const quitTime = new Date(time).getTime();
      if (inductionTime >= quitTime) {
        this.$message.warning("离职时间不能大于或等于入职时间");
        this.form.quitTime = "";
      }
    },
    // 清空表单
    deleteForm() {
      this.form = {
        position: "", //所在职务
        inductionTime: "", //入职时间
        quitTime: "", //离职时间
        talentIndustry: [], //行业类别
        entIntro: "", //公司简介
        heelerCount: "", //下属人数
        companyName: "", //公司名称
        functionType: [], //职能类型
        companyCountry: "", //所在国家
        companyProvince: "", //所在省份
        companyCity: "", //所在城市
        workIntro: "", //所在地区
        operatingDuty: "", //工作职责和业绩
        createName: "", //创建人名字
        createTime: "", //创建时间
        updateName: "", //修改人名字
        updateTime: "", //修改时间
        talentIndustryChild: [],
        id:""
      };
      this.dialogFormVisible = false;
    },
    // 查询
    async search() {
      if (this.personType || this.saveId) {
        this.queryInfo.personnelCode = this.personData.personCode
          ? this.personData.personCode
          : this.saveId;
      }
      const res = await getExperience(this.queryInfo);
      if (res.code === 200) {
        this.form2 = res.data.list;
        this.total = res.data.total;
      }
    },
    // 打开工作经历
    addWorkHistory() {
      Object.assign(this.form, defaultForm);
      this.dialogType = 0;
      this.dialogFormVisible = true;
    },
    // 添加工作经历
    async addPersonEx(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (this.form.talentIndustryChild) {
          this.form.talentIndustry = this.form.talentIndustry.concat(
            this.form.talentIndustryChild
          );
        }
        if (valid) {
          if (this.personType || this.saveId) {
            //  编辑工作
            if (this.dialogType) {
              const res = await editExperience(this.form);
              Object.assign(this.form, {
                Authorization: this.Authorization,
                personnelCode: this.personData.personCode
                  ? this.personData.personCode
                  : this.saveId,
              });
              this.deleteForm();
              this.search();
            } else {
              //  新增工作
              Object.assign(this.form, {
                Authorization: this.Authorization,
                personnelCode: this.personData.personCode
                  ? this.personData.personCode
                  : this.saveId,
                personType: "企业机构人才",
              });
              const res = await addExperience(this.form);
              this.deleteForm();
              this.search();
            }
          } else {
            if (this.dialogType) {
              //  编辑
              this.form2[this.index] = this.form;
              this.deleteForm();
            } else {
              //  新增
              this.form2.push(this.form);
              this.deleteForm();
            }
          }
        }
      });
    },
    changeArr(data) {
      if (data.talentIndustry) {
        data.talentIndustry = data.talentIndustry.split(",");
      } else {
        data.talentIndustry = [];
      }
      if (data.functionType) {
        data.functionType = data.functionType.split(",");
      } else {
        data.functionType = [];
      }
    },
    // 数组转成字符串
    changeString() {
      if (this.form.talentIndustry) {
        this.form.talentIndustry = this.form.talentIndustry.join(",");
      } else {
        this.form.talentIndustry = [];
      }
      if (this.form.functionType) {
        this.form.functionType = this.form.functionType.join(",");
      } else {
        this.form.functionType = [];
      }
    },
    // 删除工作经历
    async deleteEx(data, index) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示").then(
        async () => {
          if (this.personType || this.saveId) {
            var deleteForm = {
              id: data.id,
              personnelCode: data.personnelCode,
              personType: "企业机构人才",
            };
            const res = await deleteExperience(deleteForm);
            if (res.code == 200) {
              this.search();
            }
          } else {
            this.form2.splice(index, 1);
          }
        }
      );
    },
    // 打开编辑界面
    openEdit(data, num) {
      if (this.$refs.editin) {
        this.$refs.editin.getDicData();
      }
      if (this.$refs.editin1) {
        this.$refs.editin1.getDicData();
      }
      if (this.$refs.child) {
        this.$refs.child.search();
      }
      Object.assign(this.form, data);
      if (this.personType || this.saveId) {
        if (this.form.talentIndustry) {
          this.form.talentIndustry = this.form.talentIndustry.split(",");
        } else {
          this.form.talentIndustry = [];
        }
        if (this.form.functionType) {
          this.form.functionType = this.form.functionType.split(",");
        } else {
          this.form.functionType = [];
        }
      }
      this.index = num;
      this.dialogType = 1;
      this.dialogFormVisible = true;
    },
    searchCountry(val) {
      this.form.companyCountry = val;
    },
    searchProvince(val) {
      this.form.companyProvince = val;
    },
    searchCity(val) {
      if (val != "北京" && val != "天津" && val != "上海" && val != "重庆") {
        this.form.companyCity = val;
      }
    },
    updateCode(val, tag) {
      switch (tag) {
        case "行业":
          this.form.talentIndustryChild = val;
          break;

        default:
          break;
      }
    },
    handleClose() {
      // this.search()
      this.deleteForm();
      this.$refs.editin.clear();
      this.$refs.editin1.clear();
      this.$refs.child.clear();
    },
    cancelItem() {
      this.$router.go(-1);
    },
  },
  mounted() {
    if (this.personType) {
      document.getElementsByClassName("workHistory")[0].style.height =
        document.body.clientHeight - 320 + "px";
    }
  },
};
</script>

<style scoped lang="less">
.el-input__inner {
  width: 200px;
}
.el-button {
  padding: 12px 15px;
}
/deep/.el-dialog {
  width: 900px;
}
.index {
  // height: 560px;
  overflow: auto;
}
.bottom {
  border-top: 1px solid #dbdbdb;
  background: #fff;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  .el-button {
    padding: 8px 10px;
    margin: 0 20px;
  }
}
/deep/ .el-textarea__inner {
  height: 100px;
}
</style>
