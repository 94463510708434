<template>
  <div>
    <div class="company" v-if="personType">
      <div class="company_left">
        <upload-image
          :imageUrl.sync="enterTalentsInfo.headPortraits"
          :saveUrl.sync="enterTalentsInfo.headPortrait"
        />
        <div class="info">
          <div>
            <div class="fullName" v-if="!isShowInput">
              <div class="companyFullName">{{ enterTalentsInfo.name }}</div>
              <i class="jr-iconjr-icon-edit1" @click="editClick" />
            </div>
            <div class="fullName" v-else>
              <el-input v-model="enterTalentsInfo.name" />
              <el-button type="primary" @click="sureInfo">保 存</el-button>
            </div>
          </div>
          <div class="updateTime">
            更新时间：{{ enterTalentsInfo.updateTime }}
          </div>
        </div>
      </div>
      <div class="company_right">
        <div>创建人：{{ enterTalentsInfo.createName }}</div>
        <div>修改人：{{ enterTalentsInfo.updateName }}</div>
        <div>收录时间：{{ enterTalentsInfo.createTime }}</div>
      </div>
    </div>
    <el-form
      v-else
      :model="enterTalentsInfo"
      :inline="true"
      :rules="rulesTalentsInfo"
      ref="rulesTalentsInfo"
      class="companyInfo"
    >
      <el-form-item class="avatar">
        <upload-image :saveUrl.sync="enterTalentsInfo.headPortrait" />
      </el-form-item>
      <el-form-item label="姓名" prop="name">
        <el-input v-model="enterTalentsInfo.name" class="lager-input" />
      </el-form-item>
      <!-- <el-form-item>
        <el-button type="primary" @click="saveCompanyInfo">保存</el-button>
      </el-form-item>-->
    </el-form>
    <el-card>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="个人信息" name="first">
          <person-message
            :editTalentInfo="enterTalentsInfo"
            :personType="personType"
            ref="personMessage"
          ></person-message>
        </el-tab-pane>
        <el-tab-pane label="联系方式" name="second">
          <contact-us
            :editTalentInfo.sync="enterTalentsInfo"
            :personData="personData"
            :personType="personType"
            :saveId="saveId"
            ref="contactInfo"
          ></contact-us>
        </el-tab-pane>
        <el-tab-pane label="工作经历" name="third">
          <work-history
            :personData="personData"
            :personType="personType"
            :saveId="saveId"
            ref="workHistory"
          ></work-history>
        </el-tab-pane>
        <el-tab-pane label="项目经历" name="fourth">
          <project-history
            :personData="personData"
            :personType="personType"
            :saveId="saveId"
            ref="projectEx"
          ></project-history>
        </el-tab-pane>
        <el-tab-pane label="教育经历" name="fifth">
          <education-history
            :personData="personData"
            :personType="personType"
            :saveId="saveId"
            ref="educationEx"
          ></education-history>
        </el-tab-pane>
        <el-tab-pane label="其他能力" name="sixth">
          <other-abilities ref="otherAbilities"></other-abilities>
        </el-tab-pane>
        <el-tab-pane label="标签信息" name="seven">
          <labelInfo ref="labelInfo"></labelInfo>
        </el-tab-pane>
        <el-tab-pane label="手动标签" name="eighth">
          <labelManagement
            ref="labelManagement"
            :saveId="String(saveId)"
            :personData="personData"
          ></labelManagement>
        </el-tab-pane>
        <el-tab-pane label="半自动化标签" name="nine">
          <robotizationlabel
            ref="robotizationlabel"
            :saveId="String(saveId)"
            :personData="personData"
          ></robotizationlabel>
        </el-tab-pane>
        <!-- 删除 -->
        <!-- <el-tab-pane label="语言能力" name="sixth">
          <lang-skills
            :personData="personData"
            :personType="personType"
            ref="languageEx"
          ></lang-skills>
        </el-tab-pane>
        <el-tab-pane label="我的技能" name="seventh">
          <self-skill
            :enterTalentsInfo.sync="enterTalentsInfo"
            :personData="personData"
            :personType="personType"
            ref="selfSkill"
          ></self-skill>
        </el-tab-pane>
        <el-tab-pane label="自我评价" name="eigth">
          <self-thought
            :enterTalentsInfo.sync="enterTalentsInfo"
            :personData="personData"
            :personType="personType"
            ref="selfThoguht"
          ></self-thought>
        </el-tab-pane>
        <el-tab-pane label="附加信息" name="ninth">
          <extra-info
            :editTalentInfo.sync="enterTalentsInfo"
            :personData="personData"
            :personType="personType"
            ref="extraInfo"
          ></extra-info>
        </el-tab-pane>
        <el-tab-pane label="附件" name="tenth">
          <extra-file
            :editTalentInfo.sync="enterTalentsInfo"
            :personData="personData"
            :personType="personType"
            ref="extraFile"
          ></extra-file>
        </el-tab-pane> -->
      </el-tabs>
    </el-card>
    <div
      style="height: 50px; background: #fff;"
      v-if="this.activeName == 'nine'"
    ></div>
    <div class="bottom" v-if="this.activeName !== 'nine'">
      <el-button type="primary" @click="saveItem">保存</el-button>
      <el-button @click="cancelItem">取消</el-button>
    </div>
  </div>
</template>

<script>
import {
  updateTalents,
  addTalents,
  getTalent,
  selectPersonLabelDetail,
  insertPersonLabel,
  updatePersonLabel,
  insertPersonLabelManual,
  updatePersonLabelManual,
  getPersonOther,
  updateOtherPerson,
} from "@/api/talent";
import PersonMessage from "./editMessage/personMessage";
import ContactUs from "./editMessage/contact";
import WorkHistory from "./editMessage/workHistory";
import ProjectHistory from "./editMessage/projectHistory";
import EducationHistory from "./editMessage/educationHistory";
import OtherAbilities from "./editMessage/otherAbilities.vue";
import UploadImage from "../../../components/UploadImage.vue";
import labelInfo from "./editMessage/labelInfo.vue";
import labelManagement from "./editMessage/labelManagement.vue";
import robotizationlabel from "./editMessage/robotizationlabel.vue";
// 删除
// import LangSkills from "./editMessage/langSkills";
// import ExtraInfo from "./editMessage/extraInfo";
// import ExtraFile from "./editMessage/extraFile";
// import SelfSkill from "./editMessage/selfSkill";
// import SelfThought from "./editMessage/selfThought";
export default {
  name: "editTalentQY",
  components: {
    PersonMessage,
    ContactUs,
    WorkHistory,
    ProjectHistory,
    EducationHistory,
    OtherAbilities,
    UploadImage,
    labelInfo,
    labelManagement,
    robotizationlabel,
    // LangSkills,
    // ExtraInfo,
    // ExtraFile,
    // SelfThought,
    // SelfSkill,
  },
  mounted() {
    document.querySelector(".el-tabs__content").style.minHeight =
      document.body.clientHeight - 368 + "px";
  },
  data() {
    return {
      formddd: "",
      activeName: "first",
      isShowInput: false,
      saveId: "", //新增后保存的id
      // 表单校验信息
      enterTalentsInfo: {
        personCode: "",
        degree: "",
        name: "", //姓名
        personType: "企业机构人才",
        age: "", //年龄
        educationalBackground: "", //最高学历
        currentTechId: [], //目前技术类别
        expectedTechId: [], //期望技术类别 1
        headPortrait: "", //头像
        birthday: "", //出生日期
        sex: "", //性别
        headPortraits: "",
        personUrl: "", //个人链接
        currentCompany: "", //目前在职企业
        companyId: "", //单位名称（所属企业）
        currentIndustry: "", //所在行业
        desiredIndustry: "", //期望行业
        personCountry: "", //所在国家
        personProvince: "", //所在省份
        personCity: "", //所在城市
        expectedLocation: "", //期望地点
        researchInterest: "", //研究兴趣
        personalWebsite: "", //个人网页网站
        position: "", //所在职位（当前职位）
        expectedPosition: "", //期望职务
        cooperationStatus: "", //合作状态
        workingYears: "", //工作经验
        positionType: [], //职能类型
        diagram: "", //关系图
        unidealCompany: "", //勿推荐公司
        numberCitations: "", //论文引用书数
        publications: "", //论文数
        presentSalary: "", //目前薪资
        salaryExpectation: "", //期望薪资
        note: "", //备注
        // technique: "", //我的技能
        selfAssessment: "", //自我评价
        fax: "", //传真
        // certificate: "", //证书
        // otherOffice: "", //其它任职
        careerOrientation: "", //职业定位
        noted: "", //特别注明
        // serialNumber:"",
        // personDataIntegrity: "", //数据完整度
        // entryYearTime: "", //入职年份
        // entryMonthTime: "", //入职月份
        fieldId: [], //领域类别
        currentTechChildrenId: [],
        expectedTechChildrenId: [],
        fieldChildrenId: [],
        createName: "", //创建人名字
        createTime: "", //创建时间
        updateName: "", //修改人名字
        updateTime: "", //修改时间
        inductionTime: "", //入职时间
        companySimpleName: "", //企业简称
        // companyFullName: "", //企业全称
        companyName: "", //企业全称
      },
      rulesTalentsInfo: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
      },
    };
  },
  watch: {
    // 监听是否在手动标签页面
    activeName: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal && newVal == "eighth") {
          this.$refs.labelManagement.getBylabelId();
        }
      },
    },
  },
  computed: {
    // 编辑当前行数据  新增 111
    personData() {
      return JSON.parse(this.$route.query.personData);
    },
    // 0 新增 1 编辑
    personType() {
      return Number(this.$route.query.personType);
    },
    Authorization() {
      return sessionStorage.getItem("token");
    },
  },
  created() {
    // 存在一个personType则调用接口
    if (this.personType) {
      // this.personData = JSON.parse(this.$route.query.personData)
      this.getTalents();
      this.getOther();
      this.selectPersonLabelDetail();
    }
  },
  methods: {
    // 切换tabs页(切换到手动标签,调用查询回显的数据)
    handleClick(tab, event) {
      if (tab.index == 7 && this.personnelId) {
        this.$refs.labelManagement.getBylabelId();
      }
    },
    // 活动人才数据-企业机构人才---标签信息查询
    async selectPersonLabelDetail() {
      const res = await selectPersonLabelDetail({
        personId: this.personData.personCode,
      });
      if (res.code == 200) {
        // 如果请求的标签数据为null的情况，就处理下
        if (!res.data.personKeywordAddParamList) {
          this.$refs.labelInfo.peronLabelParam = {
            personId: "", // 企业机构主键
            keywordVersion: "", // 关键字版本
            // type: null,
            personKeywordAddParamList: [
              {
                personLabelList: [
                  {
                    groupLabelId: "", // 标签组编号
                    groupLabelName: "", // 标签组名称
                    labelId: "", // 标签编号
                    labelName: "", // 标签内容
                    reportKeywordId: null,
                    id: null,
                    superiorLabel: null, // 上级标签
                  },
                ],
                keyword: "", // 关键词
                keywordDescribe: "", // 关键词数据
                id: "",
              },
            ],
          };
        } else {
          this.$refs.labelInfo.peronLabelParam = res.data;
          // 请求数据回来后，将标签内容也改为（标签组名称+标签内容）
          res.data.personKeywordAddParamList.forEach((item) => {
            item.personLabelList.forEach((child) => {
              child.labelName = child.groupLabelName + "/" + child.labelName;
            });
          });
        }
      }
    },
    // 查询个人信息
    async getTalents() {
      const { data: res } = await getTalent(this.personData.personCode);
      this.enterTalentsInfo = res;

      this.enterTalentsInfo.currentTechId = this.formatString(
        this.enterTalentsInfo.currentTechId
      );
      this.enterTalentsInfo.expectedTechId = this.formatString(
        this.enterTalentsInfo.expectedTechId
      );
      this.enterTalentsInfo.fieldId = this.formatString(
        this.enterTalentsInfo.fieldId
      );
      this.enterTalentsInfo.positionType = this.formatString(
        this.enterTalentsInfo.positionType
      );

      // 所属企业回显
      this.$refs.personMessage.options = [
        {
          companyCode: res.companyId,
          companyFullName: res.companyName,
        },
      ];
      // 企业简称回显
      this.$refs.personMessage.companySimpleName = res.companySimpleName;
      // 如果返回的数据，是所属企业新增的数据，那就将获取到的所属企业名称赋值给子组件
      if (!res.companyId) {
        this.$refs.personMessage.companyName = res.companyName;
        this.$refs.personMessage.companyId = res.companyName;
        this.$refs.personMessage.companySimpleName = res.companySimpleName;
      }
    },
    // 查询其他能力
    async getOther() {
      const res = await getPersonOther({
        personCode: this.personData.personCode,
      });
      if (!res.code) {
        if (res) {
          this.$refs.otherAbilities.otherForm = res;
        } else {
          this.$refs.otherAbilities.otherForm = {
            languages: [], //所习语言
            abilityCertificate: [""], //能力证书
            technique: [""], //个人技能
            selfAssessment: "", //自我评价
            certificate: [""], //证书名称
            otherOffice: [""], //其他任职
            careerOrientation: "", //职业定位
            noted: "", //特别注明
            personalFile: "", //附件
          };
        }
      } else {
        this.$refs.otherAbilities.otherForm = {
          languages: [], //所习语言
          abilityCertificate: [""], //能力证书
          technique: [""], //个人技能
          selfAssessment: "", //自我评价
          certificate: [""], //证书名称
          otherOffice: [""], //其他任职
          careerOrientation: "", //职业定位
          noted: "", //特别注明
          personalFile: "", //附件
        };
      }
    },
    // 判断是否为字符串
    formatString(val) {
      if (!val) {
        return [];
      } else {
        return val.split(",");
      }
    },
    // 当为编辑部分，提交上部信息 更新部分个人信息
    async sureInfo() {
      const res = await updateTalents(this.enterTalentsInfo);
      this.isShowInput = false;
    },
    // 当为新增部分时，提交上部分信息
    saveCompanyInfo() {
      this.$refs.rulesTalentsInfo.validate(async (valid) => {
        if (valid) {
          const res = await addTalents(this.enterTalentsInfo);
          if (res.code === 200) {
            this.$message.success("新增成功");
          }
        }
      });
    },
    // 保存
    async save(res, path) {
      Object.assign(this.enterTalentsInfo, {
        Authorization: this.Authorization,
      });
      let contact = this.$refs.contactInfo.ruleForm; //联系方式的数据
      let workHistory = this.$refs.workHistory.form2; //工作经历
      let projectEx = this.$refs.projectEx.form2;
      let educationEx = this.$refs.educationEx.form2;
      let otherForm = this.$refs.otherAbilities.otherForm; //其他能力
      // let languageEx = this.$refs.languageEx.form2; //语言能力
      // let extraFile = this.$refs.extraFile.form2; //附件
      // let extraInfo = this.$refs.extraInfo.ruleForm; //附加信息的数据
      Object.assign(contact, {
        Authorization: this.Authorization,
        personCode: this.saveId ? this.saveId : this.personData.personCode,
      });
      Object.assign(otherForm, {
        Authorization: this.Authorization,
        personCode: this.saveId ? this.saveId : this.personData.personCode,
      });
      // Object.assign(extraInfo, {
      //   Authorization: this.Authorization,
      // });

      if (this.enterTalentsInfo.fieldChildrenId) {
        this.enterTalentsInfo.fieldId = this.enterTalentsInfo.fieldId.concat(
          this.enterTalentsInfo.fieldChildrenId
        );
      }
      if (this.enterTalentsInfo.currentTechChildrenId) {
        this.enterTalentsInfo.currentTechId =
          this.enterTalentsInfo.currentTechId.concat(
            this.enterTalentsInfo.currentTechChildrenId
          );
      }
      if (this.enterTalentsInfo.expectedTechChildrenId) {
        this.enterTalentsInfo.expectedTechId =
          this.enterTalentsInfo.expectedTechId.concat(
            this.enterTalentsInfo.expectedTechChildrenId
          );
      }
      if (
        this.enterTalentsInfo.fieldId[0] == null ||
        this.enterTalentsInfo.fieldId[0] == "null"
      ) {
        this.enterTalentsInfo.fieldId = [];
      }
      if (
        this.enterTalentsInfo.expectedTechId[0] == null ||
        this.enterTalentsInfo.expectedTechId[0] == "null"
      ) {
        this.enterTalentsInfo.expectedTechId = [];
      }
      if (
        this.enterTalentsInfo.currentTechId[0] == null ||
        this.enterTalentsInfo.currentTechId[0] == "null"
      ) {
        this.enterTalentsInfo.currentTechId = [];
      }

      // 将子组件的企业简称在保存前赋值给父组件
      if (this.$refs.personMessage.companySimpleName) {
        this.enterTalentsInfo.companySimpleName =
          this.$refs.personMessage.companySimpleName;
      }

      // 如果所属企业的名称是没有关联的，那就赋值名字
      if (this.$refs.personMessage.companyName) {
        this.enterTalentsInfo.companyName =
          this.$refs.personMessage.companyName;
        this.enterTalentsInfo.companyId = null;
      }
      // 如果所属企业是自己新增的，那就将之前查询时有的所属企业id置空
      if (this.enterTalentsInfo.companyId) {
        this.enterTalentsInfo.companyName = null;
      }

      const params = {
        personDto: this.enterTalentsInfo,
        personContact: contact,
        peDtos: workHistory,
        prEDtos: projectEx,
        personEducationals: educationEx,
        // languages: languageEx,
        // additionalInformations: extraInfo,
        // personFiles: extraFile,
        thesisDtos: [],
        patents: [],
        projects: [],
        personOther: otherForm, //其他能力
      };
      // 新增
      if (!this.personType) {
        if (!this.saveId) {
          if (this.enterTalentsInfo.name == "") {
            this.$message.error("请填写人才名称");
          } else {
            res = await addTalents(params);
          }
        } else {
          // 如果不是其他能力就正常调用编辑接口，如果是就调用自己的接口
          if (this.activeName !== "sixth" && this.activeName !== "second") {
            res = await updateTalents(params.personDto);
          } else if (this.activeName == "sixth") {
            res = await updateOtherPerson(params.personOther);
          } else if (this.activeName == "second") {
            this.$refs.contactInfo.submitForm();
            if (this.$refs.contactInfo.activeName) {
              this.activeName = this.$refs.contactInfo.activeName;
            }
          }
        }
      } else {
        if (this.activeName !== "sixth" && this.activeName !== "second") {
          res = await updateTalents(params.personDto);
        } else if (this.activeName == "sixth") {
          res = await updateOtherPerson(params.personOther);
        } else if (this.activeName == "second") {
          this.$refs.contactInfo.submitForm();
          if (this.$refs.contactInfo.activeName) {
            this.activeName = this.$refs.contactInfo.activeName;
          }
        }
      }
      if (res.code == 200) {
        this.$message.success(`${this.personType ? "编辑" : "新增"}成功`);
        // 赋值新增的唯一id
        if (
          res.msg !== "修改成功" &&
          res.msg !== null &&
          res.msg !== "编辑成功" &&
          res.msg !== "修改失败"
        ) {
          this.saveId = res.msg;
          this.enterTalentsInfo.personCode = res.msg;
          this.activeName = path;
        } else {
          this.activeName = path;
        }
      } else {
        `${this.personType ? "编辑" : "新增"}失败`;
      }
    },
    // 全部保存(除了标签信息，手动标签，半自动化标签外)
    async saveItem() {
      let res;
      switch (this.activeName) {
        case "first":
          this.$refs.personMessage.$refs.editTalentInfo.validate(
            async (valid) => {
              if (valid) {
                this.save(res, "second");
              }
            }
          );
          break;
        case "second":
          this.$refs.personMessage.$refs.editTalentInfo.validate(
            async (valid) => {
              if (valid) {
                // 编辑
                if (this.personType || this.saveId) {
                  const res = this.$refs.contactInfo.submitForm();
                  res.then((rs) => {
                    if (rs == 200) {
                      this.$message.success("修改成功");
                      this.activeName = "third";
                    }
                  });
                } else {
                  this.save(res, "third");
                }
              }
            }
          );

          break;
        case "third":
          this.$refs.personMessage.$refs.editTalentInfo.validate(
            async (valid) => {
              if (valid) {
                // 编辑
                if (this.personType || this.saveId) {
                  this.activeName = "fourth";
                } else {
                  this.save(res, "fourth");
                }
              }
            }
          );
          break;
        case "fourth":
          this.$refs.personMessage.$refs.editTalentInfo.validate(
            async (valid) => {
              if (valid) {
                // 编辑
                if (this.personType || this.saveId) {
                  this.activeName = "fifth";
                } else {
                  this.save(res, "fifth");
                }
              }
            }
          );
          break;
        case "fifth":
          this.$refs.personMessage.$refs.editTalentInfo.validate(
            async (valid) => {
              if (valid) {
                // 编辑
                if (this.personType || this.saveId) {
                  this.activeName = "sixth";
                } else {
                  this.save(res, "sixth");
                }
              }
            }
          );
          break;
        case "sixth":
          this.$refs.personMessage.$refs.editTalentInfo.validate(
            async (valid) => {
              if (valid) {
                // 编辑
                if (this.personType || this.saveId) {
                  this.save(res, "seven");
                } else {
                  this.save(res, "seven");
                }
              }
            }
          );
          break;
        case "seven":
          //最终组装的数组
          let arr = [];
          //开始添加
          this.$refs.labelInfo.peronLabelParam.personKeywordAddParamList.forEach(
            (item) => {
              let keyword = item.keyword;
              let keywordDescribe = item.keywordDescribe;
              let id = item.id;
              let personLabelList = [];
              // 遍历我添加的数组
              item.personLabelList.forEach((child) => {
                // 遍历总数组
                this.$refs.labelInfo.labelOptions.forEach((item1) => {
                  if (item1.labelKeywordResultList[0].id == child.labelId) {
                    item1.labelKeywordResultList.forEach((name) => {
                      name = {
                        groupLabelId: name.groupLabelPrefix,
                        groupLabelName: name.groupLabelType,
                        labelId: name.id,
                        labelName: name.labelName,
                        labelStatus:
                          item1.labelKeywordResultList[0].id == name.id ? 1 : 0,
                      };
                      personLabelList.push(name);
                    });
                  }
                });
              });

              let all = {
                keyword: keyword,
                keywordDescribe: keywordDescribe,
                personLabelList: personLabelList,
                id: id,
              };
              arr.push(all);
            }
          );

          // 最终提交后端的数据
          let peronLabelParam = {
            keywordVersion: this.$refs.labelInfo.peronLabelParam.keywordVersion, //关键字版本
            personId: this.saveId ? this.saveId : this.personData.personCode,
            personKeywordAddParamList: arr,
          };
          // 判断是编辑还是新增 1编辑  0新增
          if (!this.personType) {
            // 如果未保存基本信息
            if (!this.saveId) {
              this.$message.error("请先保存基本信息的数据再进行下一步操作！");
            } else {
              this.$refs.labelInfo.$refs.labelInfoForm.validate(
                async (valid) => {
                  if (valid) {
                    res = await insertPersonLabel(peronLabelParam);
                    if (res.code == 200) {
                      this.$message.success("新增成功!");
                      this.activeName = "eighth";
                    } else {
                      this.$message.error("新增失败，请稍候重试!");
                    }
                  }
                }
              );
            }
          } else {
            this.$refs.labelInfo.$refs.labelInfoForm.validate(async (valid) => {
              if (valid) {
                res = await updatePersonLabel(peronLabelParam);
                if (res.code == 200) {
                  this.$message.success("编辑成功!");
                  this.activeName = "eighth";
                } else {
                  this.$message.error("编辑失败，请稍候重试!");
                }
              }
            });
          }
          break;
        case "eighth":
          if (this.saveId || this.personData.personCode) {
            let query = [];

            this.$refs.labelManagement.radiolist.forEach((el) => {
              if (el.labelparam.labelNameList.length > 0) {
                el.labelparam.personId = this.saveId
                  ? this.saveId
                  : this.personData.personCode;
                query.push(el.labelparam);
              }
            });
            if (query.length > 0) {
              if (!this.$refs.labelManagement.labeList.length) {
                insertPersonLabelManual(query).then((response) => {
                  if (response.code == 200) {
                    this.$message.success("保存成功");
                    this.activeName = "nine";
                  } else {
                    this.$message.error(response.data.message);
                  }
                });
              } else {
                updatePersonLabelManual(query).then((response) => {
                  if (response.code == 200) {
                    this.$message.success("编辑成功");
                    this.activeName = "nine";
                  } else {
                    this.$message.error(response.data.message);
                  }
                });
              }
            } else {
              this.$message.warning("请勾选标签！");
            }
          } else {
            this.$message.error("请先保存基本信息的数据再进行下一步操作！");
          }
          break;
        case "nine":
          break;

        default:
          break;
      }
    },
    // 返回
    cancelItem() {
      this.$router.push({
        name: "Talents",
      });
    },
    editClick() {
      this.isShowInput = true;
    },

    // 之前的保存方法
    // saveItem() {
    //   this.activeName = "seven";
    //   Object.assign(this.enterTalentsInfo, {
    //     Authorization: this.Authorization,
    //   });
    //   let contact = this.$refs.contactInfo.ruleForm; //联系方式的数据
    //   let extraInfo = this.$refs.extraInfo.ruleForm; //附加信息的数据
    //   let workHistory = this.$refs.workHistory.form2; //工作经历
    //   let projectEx = this.$refs.projectEx.form2;
    //   let educationEx = this.$refs.educationEx.form2;
    //   let languageEx = this.$refs.languageEx.form2;
    //   let extraFile = this.$refs.extraFile.form2;
    //   Object.assign(contact, {
    //     Authorization: this.Authorization,
    //   });
    //   Object.assign(extraInfo, {
    //     Authorization: this.Authorization,
    //   });
    //   let res;
    //   this.$refs.personMessage.$refs.editTalentInfo.validate(async (valid) => {
    //     //async
    //     if (valid) {
    //       if (this.enterTalentsInfo.fieldChildrenId) {
    //         this.enterTalentsInfo.fieldId =
    //           this.enterTalentsInfo.fieldId.concat(
    //             this.enterTalentsInfo.fieldChildrenId
    //           );
    //       }
    //       if (this.enterTalentsInfo.currentTechChildrenId) {
    //         this.enterTalentsInfo.currentTechId =
    //           this.enterTalentsInfo.currentTechId.concat(
    //             this.enterTalentsInfo.currentTechChildrenId
    //           );
    //       }
    //       if (this.enterTalentsInfo.expectedTechChildrenId) {
    //         this.enterTalentsInfo.expectedTechId =
    //           this.enterTalentsInfo.expectedTechId.concat(
    //             this.enterTalentsInfo.expectedTechChildrenId
    //           );
    //       }
    //       if (
    //         this.enterTalentsInfo.fieldId[0] == null ||
    //         this.enterTalentsInfo.fieldId[0] == "null"
    //       ) {
    //         this.enterTalentsInfo.fieldId = [];
    //       }
    //       if (
    //         this.enterTalentsInfo.expectedTechId[0] == null ||
    //         this.enterTalentsInfo.expectedTechId[0] == "null"
    //       ) {
    //         this.enterTalentsInfo.expectedTechId = [];
    //       }
    //       if (
    //         this.enterTalentsInfo.currentTechId[0] == null ||
    //         this.enterTalentsInfo.currentTechId[0] == "null"
    //       ) {
    //         this.enterTalentsInfo.currentTechId = [];
    //       }
    //       const params = {
    //         personDto: this.enterTalentsInfo,
    //         personContact: contact,
    //         peDtos: workHistory,
    //         prEDtos: projectEx,
    //         personEducationals: educationEx,
    //         languages: languageEx,
    //         additionalInformations: extraInfo,
    //         personFiles: extraFile,
    //         thesisDtos: [],
    //         patents: [],
    //         projects: [],
    //       };
    //       if (this.personType) {
    //         // 编辑
    //         res = await updateTalents(this.enterTalentsInfo);
    //       } else {
    //         // 新增
    //         if (this.enterTalentsInfo.name == "") {
    //           this.$message.error("请填写人才名称");
    //         } else {
    //           res = await addTalents(params);
    //         }
    //       }
    //       if (res.code === 200) {
    //         this.$message.success(`${this.personType ? "编辑" : "新增"}成功`);
    //         this.$router.go(-1);
    //       }
    //     }
    //   });
    // },
  },
};
</script>

<style lang="less" scoped>
.company {
  background: #fff;
  padding: 20px 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .company_left {
    display: flex;
    .info {
      margin-left: 20px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      .fullName {
        display: flex;
        align-items: center;
        .el-button {
          margin-left: 10px;
        }
        .companyFullName {
          font-size: 24px;
          color: #363e4d;
        }
        i {
          margin-left: 10px;
        }
      }

      .updateTime {
        color: #7c7f8e;
        font-size: 12px;
        margin-top: 5px;
      }
    }
  }
  .company_right {
    font-size: 12px;
    color: #7c7f8e;
  }
}
/deep/ .el-tabs__content {
  overflow: auto;
}
.companyInfo {
  background: #fff;
  padding: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  .el-form-item {
    margin-bottom: 0;
  }
  .avatar {
    margin-right: 20px;
  }
}

.bottom {
  border-top: 1px solid #dbdbdb;
  background: #fff;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  .el-button {
    margin: 0 20px;
  }
}

/deep/ .el-card__body {
  padding: 0px 20px 0px 20px;
}

/deep/ .jr-iconjr-icon-edit1:hover {
  color: #4d77ff;
  cursor: pointer;
}
</style>
