import request from '@/utils/request'

// 案例数据查询
export function getCaseData(data) {
  return request({
    url: '/system/Plan/getPlanPageDto',
    method: 'post',
    data
  })
}

// 单个查询
export function findScheme(data) {
  return request({
    url: '/system/Plan/getPlanList',
    method: 'get',
    params: data
  })
}

// 案例数据新增
export function addCaseData(data) {
  return request({
    url: "/system/Plan/insertPlan",
    method: "post",
    data
  })
}

// 案例数据编辑
export function updateCaseData(data) {
  return request({
    url: "/system/Plan/updatePlan",
    method: "post",
    data
  })
}

// 案例数据删除
export function deleteCaseData(data) {
  return request({
    url: "/system/Plan/deleteProjectById",
    method: "post",
    data
  })
}

// 案例数据批量上传
export function uploadImportCase(data) {
  return request({
    url: "/system/Plan/importplan",
    method: "post",
    data
  })
}

// 报告数据智能查询
export function findReport(data) {
  return request({
    url: "/system/Plan/getByReportName",
    method: "get",
    params: data
  })
}

// 企业名称智能查询
export function findCompany(data) {
  return request({
    url: "/system/Plan/getByCompanyName",
    method: "get",
    params: data
  })
}

// 方案裁决智能查询
export function findPlan(data) {
  return request({
    url: "/system/Plan/getByCompanyCode/",
    method: "get",
    params: data
  })
}

// 案例标签信息新增
export function insertPlanLabel(data) {
  return request({
    url: "/system/planLabel/insertPlanLabel",
    method: "post",
    data
  })
}

// 案例标签信息编辑
export function updatePlanLabel(data) {
  return request({
    url: "/system/planLabel/updatePlanLabel",
    method: "post",
    data
  })
}

// 案例标签信息查询
export function selectPlanLabelDetail(data) {
  return request({
    url: "/system/planLabel/selectPlanLabelDetail",
    method: "get",
    params: data
  })
}

// 查询当前企业所有的标签
export function getById(data) {
  return request({
    url: "/system/planLabel/getById",
    method: "get",
    params: data
  })
}

// 手动打标签新增
export function insertPlanLabelManual(data) {
  return request({
    url: "/system/planLabel/insertPlanLabelManual",
    method: "post",
    data
  })
}

// 手动打标签编辑更新
export function updatePlanLabelManual(data) {
  return request({
    url: "/system/planLabel/updatePlanLabelManual",
    method: "post",
    data
  })
}

// 查询所有标签组
export function selectAllLabelGroup(data) {
  return request({
    url: "/system/planLabel/selectAllLabelGroup",
    method: "get",
    params: data
  })
}

// 模糊查询标签组
export function selectLikeGroupLabelName(data) {
  return request({
    url: "/system/planLabel/selectLikeGroupLabelName",
    method: "get",
    params: data
  })
}

// 保存已选标签--案例
// export function insertMatchPlanLabel(data) {
//   return request({
//     url: "/system/label/insertMatchPlanLabel",
//     method: "post",
//     data
//   })
// }

// 案例-关联产品查询
export function getProductByName(data) {
  return request({
    url: "/system/Plan/getProductByName",
    method: "get",
    params: data
  })
}

// 案例-关联场景智能查询
export function getByScenarioName(data) {
  return request({
    url: "/system/Plan/getByScenarioName",
    method: "get",
    params: data
  })
}

// 案例-关联方案智能查询
export function getBySolutionName(data) {
  return request({
    url: "/system/Plan/getBySolutionName",
    method: "get",
    params: data
  })
}

// 案例-智参企业智能查询
export function getZcByCompanyName(data) {
  return request({
    url: "/system/Plan/getZcByCompanyName",
    method: "get",
    params: data
  })
}

// 案例-半自动标签查询
export function findHistroyMatchedLabelByPlan(data) {
  return request({
    url: "/system/label/findHistroyMatchedLabelByPlan",
    method: "get",
    params: data
  })
}

// 案例--半自动标签修改
export function updateMatchedInformationLabel(data) {
  return request({
    url: "/system/label/updateMatchedInformationLabel",
    method: "post",
    data
  })
}

// 案例--半自动标签新增
export function insertMatchPlanLabel(data) {
  return request({
    url: "/system/label/insertMatchPlanLabel",
    method: "post",
    data
  })
}
