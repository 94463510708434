<template>
  <div class="otherAbilities">
    <el-form ref="otherAbilitiesForm" :model="otherForm" label-width="80px">
      <el-form-item label="所习语言:">
        <el-checkbox-group v-model="otherForm.languages">
          <el-checkbox label="中文"></el-checkbox>
          <el-checkbox label="英文"></el-checkbox>
          <el-checkbox label="日文"></el-checkbox>
          <el-checkbox label="其他"></el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <div
        v-for="(item, index) in otherForm.abilityCertificate"
        :key="index + 'b'"
        style="display: flex"
      >
        <el-form-item label="能力证书:" size="small" style="width: 700px">
          <el-input
            v-model="otherForm.abilityCertificate[index]"
            :autosize="{ minRows: 3 }"
            type="textarea"
          ></el-input>
        </el-form-item>
        <span style="color: #4e93fb" @click="addBook(item)">
          <i class="el-icon-circle-plus-outline"></i>
        </span>
        <span v-if="index > 0" style="color: #ff7575" @click="delBook(index)">
          <i class="el-icon-remove-outline"></i>
        </span>
      </div>
      <div
        v-for="(item, index) in otherForm.technique"
        :key="index + 'jn'"
        style="display: flex"
      >
        <el-form-item label="个人技能:" size="small" style="width: 700px">
          <el-input
            v-model="otherForm.technique[index]"
            :autosize="{ minRows: 3 }"
            type="textarea"
          ></el-input>
        </el-form-item>
        <span style="color: #4e93fb" @click="addSkill(item)">
          <i class="el-icon-circle-plus-outline"></i>
        </span>
        <span v-if="index > 0" style="color: #ff7575" @click="delSkill(index)">
          <i class="el-icon-remove-outline"></i>
        </span>
      </div>
      <el-form-item label="自我评价:" size="small" style="width: 700px">
        <el-input
          v-model="otherForm.selfAssessment"
          :autosize="{ minRows: 3 }"
          type="textarea"
        ></el-input>
      </el-form-item>
      <div
        v-for="(item, index) in otherForm.certificate"
        :key="index + 'na'"
        style="display: flex"
      >
        <el-form-item label="证书名称:" size="small" style="width: 700px">
          <el-input
            v-model="otherForm.certificate[index]"
            :autosize="{ minRows: 3 }"
            type="textarea"
          ></el-input>
        </el-form-item>
        <span style="color: #4e93fb" @click="addName(item)">
          <i class="el-icon-circle-plus-outline"></i>
        </span>
        <span v-if="index > 0" style="color: #ff7575" @click="delName(index)">
          <i class="el-icon-remove-outline"></i>
        </span>
      </div>
      <div
        v-for="(item, index) in otherForm.otherOffice"
        :key="index + 'rzs'"
        style="display: flex"
      >
        <el-form-item label="其他任职:" size="small" style="width: 700px">
          <el-input
            v-model="otherForm.otherOffice[index]"
            :autosize="{ minRows: 3 }"
            type="textarea"
          ></el-input>
        </el-form-item>
        <span style="color: #4e93fb" @click="addTake(item)">
          <i class="el-icon-circle-plus-outline"></i>
        </span>
        <span v-if="index > 0" style="color: #ff7575" @click="delTake(index)">
          <i class="el-icon-remove-outline"></i>
        </span>
      </div>
      <el-form-item label="职业定位:" size="small" style="width: 700px">
        <el-input
          v-model="otherForm.careerOrientation"
          :autosize="{ minRows: 3 }"
          type="textarea"
        ></el-input>
      </el-form-item>
      <el-form-item label="特别注明:" size="small" style="width: 700px">
        <el-input
          v-model="otherForm.noted"
          :autosize="{ minRows: 3 }"
          type="textarea"
        ></el-input>
      </el-form-item>
      <el-form-item label="附件:">
        <div
          class="name_form"
          style="width: 700px; display: flex; justify-content: space-between"
        >
          <el-input
            v-model="otherForm.personFile"
            placeholder="请上传产品附件"
          />
          <el-upload
            action
            class="upload-demo"
            ref="upload"
            :show-file-list="false"
            :before-upload="beforeUploadprods"
            :http-request="
              (res) => {
                return uploadprods(res);
              }
            "
          >
            <el-button
              style="position: relative; left: -76px"
              slot="trigger"
              size="mini"
              type="primary"
              plain
              >上传附件</el-button
            >
          </el-upload>
        </div>
        <span style="color: #909399"
          >支持doc、docx、xls、xlsx、ppt、pptx、pdf格式</span
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import cos from "../../../../utils/cos";
export default {
  data() {
    return {
      // 表单数据
      otherForm: {
        languages: [], //所习语言
        abilityCertificate: [""], //能力证书
        technique: [""], //个人技能
        selfAssessment: "", //自我评价
        certificate: [""], //证书名称
        otherOffice: [""], //其他任职
        careerOrientation: "", //职业定位
        noted: "", //特别注明
        personFile: "", //附件
      },
    };
  },
  methods: {
    // 添加能力证书
    addBook() {
      this.otherForm.abilityCertificate.push("");
    },
    delBook(index) {
      this.otherForm.abilityCertificate.splice(index, 1);
    },
    // 添加个人技能
    addSkill() {
      this.otherForm.technique.push("");
    },
    delSkill(index) {
      this.otherForm.technique.splice(index, 1);
    },
    // 添加证书名称
    addName() {
      this.otherForm.certificate.push("");
    },
    delName(index) {
      this.otherForm.certificate.splice(index, 1);
    },
    // 添加其他任职
    addTake() {
      this.otherForm.otherOffice.push("");
    },
    delTake(index) {
      this.otherForm.otherOffice.splice(index, 1);
    },

    //上传附件 (上传文件之前的钩子，参数为上传的文件)
    async beforeUploadprods(file) {
      this.file = file;
      // 拿到上传文件的名字
      this.filename = file.name;
      // 要求上传格式
      let format = file.name.split(".")[1];
      // 限制上传的要求
      let formatList = ["doc", "docx", "xls", "xlsx", "ppt", "pptx", "pdf"];
      // 判断上传的文件是否符合要求
      const extension = formatList.indexOf(format);
      if (extension == -1) {
        this.$message.warning("上传模板不支持此格式");
        return;
      }
      // 异步读取文件机制
      let reader = new FileReader();
      // 读取文件内容，结果用data:url的字符串形式表示
      reader.readAsDataURL(file);

      let that = this;
      // readAsDataURL 方法会读取指定的 Blob 或 File 对象，并生成data URl(base64编码)
      reader.onload = function () {
        that.fileData = reader.result;
      };
      return false;
    },
    //上传附件
    uploadprods(params, index) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      /* 直接调用 cos sdk 的方法 */
      cos
        .uploadFile({
          Bucket: "ginseng-wisdom-1300257084" /* 填写自己的 bucket，必须字段 */,
          Region: "ap-nanjing" /* 存储桶所在地域，必须字段 */,
          Key:
            "system_img/" +
            params.file
              .name /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */,
          Body: params.file, // 上传文件对象
          SliceSize:
            1024 *
            1024 *
            500 /* 触发分块上传的阈值，超过5MB 使用分块上传，小于5MB使用简单上传。可自行设置，非必须 */,
        })
        .then((res) => {
          if (res.statusCode === 200) {
            this.otherForm.personFile = res.Location;
            loading.close();
          }
        })
        .catch((err) => {
          loading.close();
          console.log("上传出错", err);
        });
    },
  },
};
</script>

<style scoped lang="less">
// 删除和新增图标样式
.otherAbilities {
  /deep/ .el-icon-circle-plus-outline {
    font-size: 31px;
    vertical-align: middle;
    color: #698eef;
    cursor: pointer;
    padding-left: 10px;
  }
  /deep/ .el-icon-remove-outline {
    font-size: 31px;
    vertical-align: middle;
    color: red;
    cursor: pointer;
    padding-left: 10px;
  }
}
</style>
